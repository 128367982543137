import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { format } from "date-fns";
import QuidTextField from "components/atoms/QuidTextField";
import { UpdatePeopleParams } from "entities/clients/People";
import { Ubo } from "entities/clients/PersonEntity";

const useStyles = makeStyles(() => ({
  gridFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gridContainer: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
  },
  textField: {
    width: "90%",
    backgroundColor: "#FFFFFF",
  },
  updateBtn: { display: "block", margin: "20px auto 70px auto" },
}));

export interface UboInfoProps {
  ubo: Ubo;
  updateUbo: (params: UpdatePeopleParams, note: string) => Promise<void>;
}

type FormValues = {
  name?: string;
  surname?: string;
  birthDate?: string | Date;
  nationality?: string;
  countryOfBirth?: string;
  note?: string;
  percentageOfOwnership?: string;
};

const UboInfo: React.FC<UboInfoProps> = ({ ubo, updateUbo }) => {
  const classes = useStyles();
  const { t } = useTranslation("ubo");

  const onFormSubmit = (values: FormValues) => {
    return updateUbo(
      {
        id: ubo.person.id.toString(),
        name: values.name as string,
        surname: values.surname as string,
        birthDate: values.birthDate as string,
        nationality: values.nationality as string,
        ownershipPercentage: values?.percentageOfOwnership,
        countryOfBirth: values.countryOfBirth as string,
      },
      values.note as string
    );
  };

  const defaultValues: FormValues = {
    name: "",
    surname: "",
    birthDate: "",
    nationality: "",
    countryOfBirth: "",
    note: " ",
    percentageOfOwnership: " ",
  };
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      reset({
        name: ubo?.person?.name,
        surname: ubo?.person?.surname,
        birthDate:
          ubo?.person?.birthDate instanceof Date
            ? format(ubo?.person?.birthDate, "yyyy-MM-dd")
            : (ubo?.person?.birthDate as string),
        nationality: ubo?.person?.nationality,
        countryOfBirth: ubo?.person?.countryOfBirth,
        percentageOfOwnership: ubo?.percentageOfOwnership,
        note: ubo?.note,
      });
    }

    return (): void => {
      isMounted = false;
    };
  }, [ubo, reset]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container direction="row" className={classes.gridContainer}>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="name"
                textFieldProps={{
                  className: classes.textField,
                }}
                rules={{
                  required: t("details__label__name__error") as string,
                }}
                label={t("details__label__name")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="surname"
                textFieldProps={{
                  className: classes.textField,
                }}
                rules={{
                  required: t("details__label__surname__error") as string,
                }}
                label={t("details__label__surname")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="birthDate"
                rules={{
                  required: t("details__label__birthDate__error") as string,
                }}
                textFieldProps={{
                  type: "date",
                  className: classes.textField,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                label={t("details__label__birthDate")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="nationality"
                textFieldProps={{
                  className: classes.textField,
                }}
                rules={{
                  required: t("details__label__nationality__error") as string,
                }}
                label={t("details__label__nationality")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="countryOfBirth"
                textFieldProps={{
                  className: classes.textField,
                }}
                rules={{
                  required: t(
                    "details__label__countryOfBirth__error"
                  ) as string,
                }}
                label={t("details__label__countryOfBirth")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={true}
                name="percentageOfOwnership"
                textFieldProps={{
                  className: classes.textField,
                }}
                rules={{
                  required: t(
                    "details__label__ownership_percentage__error"
                  ) as string,
                }}
                label={t("details__label__ownership_percentage")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                disabled={false}
                name="note"
                textFieldProps={{
                  className: classes.textField,
                }}
                rules={{}}
                label={t("details__label__note")}
                defaultValues={defaultValues}
              />
            </Grid>
          </Grid>
          <Button
            className={classes.updateBtn}
            variant="contained"
            type="submit"
            color="primary"
          >
            Update
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

export default UboInfo;
