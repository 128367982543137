import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router";
import ApproveRejectKycCorporateBox from "../../atoms/ApproveRejectKycCorporateBox";
import { ColDef } from "@material-ui/data-grid";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { getPartiesCountries } from "store/reducers/app.reducer";
import AttachmentWithoutPreview from "components/molecules/verifications/AttachmentWithoutPreview";
import VerificationTemplate from "templates/VerificationTemplate";
import { formatDate } from "utils";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import ReactJson from "react-json-view";
import QuidTitle from "components/atoms/QuidTitle";
import { acceptTask, rejectTask } from "api/tasks";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import { Document } from "entities/clients/DocumentEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

export interface IdentityDetails {
  [key: string]: string;
}

export interface CompanyDetails {
  id: number;
  companyName: string;
  companyNumber: string;
  legalEntityType: string;
  legalEntityValue: string;
  authorized: string;
  incorporationDate: string;
}

export interface BusinessResult {
  fourStopCorporateId: number;
  status: string;
  complete: string;
  results?: string;
}

const FourStopCorporateVerification: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
}> = ({ columns, isHistory, task, refetch }) => {
  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();

  const taskVariables = task?.variables;
  const personDetail = taskVariables?.person;
  const addressDetail = taskVariables?.address;
  const companyDetails = taskVariables?.company;
  const identityDocs = task?.variables?.identityDocuments?.filter(
    (d: any) => d.type === "ID_DOCUMENT"
  );
  const proofOfAddressDocument = task?.variables?.addressDocuments?.find(
    (d: any) => d.type === "PROOF_OF_ADDRESS"
  );
  const fourStopBusinessResult = task?.variables?.fourStopBusinessResult;
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;
  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  const toPrintPerson: IdentityDetails = {
    personId: personDetail?.id,
    personName: personDetail?.name,
    personSurname: personDetail?.surname,
    personBirthDate: personDetail?.birthDate
      ? formatDate(personDetail.birthDate)
      : "n/a",
    personCountryOfBirth: getCountry(personDetail?.countryOfBirth) || "",
    personNationality: getCountry(personDetail?.nationality) || "",
    personType: taskVariables?.personType,
  };

  const toPrintCompany: CompanyDetails = {
    id: companyDetails?.id || "n/a",
    companyName: companyDetails?.companyName || "n/a",
    companyNumber: companyDetails?.companyNumber || "n/a",
    legalEntityType: companyDetails?.legalEntityType || "n/a",
    legalEntityValue: companyDetails?.legalEntityValue || "n/a",
    authorized: companyDetails?.authorized ? "true" : "false",
    incorporationDate:
      formatDate(companyDetails?.incorporationDate, "dd/MMM/yyyy") || "n/a",
  };

  const toPrintAddress: IdentityDetails = {
    addressLine1: addressDetail?.addressLine1,
    addressLine2: addressDetail?.addressLine2 || "--",
    city: addressDetail?.city,
    country: getCountry(addressDetail?.country) || "",
    postalCode: addressDetail?.postalCode,
  };

  const toPrintBusinessResult: BusinessResult = {
    fourStopCorporateId: fourStopBusinessResult?.fourStopCorporateId,
    status: fourStopBusinessResult?.status,
    complete: fourStopBusinessResult?.complete ? "true" : "false",
  };

  if (!task) {
    return null;
  }

  return (
    <VerificationTemplate
      refetch={refetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {personDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={toPrintPerson}
              label="person_details__title"
            />
          </div>
        )}
        {companyDetails && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              label="document__company__title"
              toPrint={toPrintCompany}
            />
          </div>
        )}
        {addressDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={toPrintAddress}
              label="addressDetails__title"
            />
          </div>
        )}
        {(proofOfAddressDocument || identityDocs) && (
          <div className={classes.detailsWrapperThird}>
            {proofOfAddressDocument && (
              <div>
                <DetailsBlock label="document__proofOfAddressPreview__title" />
                <AttachmentWithoutPreview
                  attachment={proofOfAddressDocument}
                  downloadDoc={(params) =>
                    isHistory === undefined
                      ? fetchDocument({ ...params, taskId, taskKey })
                      : fetchHistoricalDocument({ ...params, taskId, taskKey })
                  }
                />
                <br />
              </div>
            )}
            {identityDocs?.length &&
              identityDocs.map((iDoc: Document) => (
                <div>
                  <DetailsBlock label="document__proofOfIdentity__title" />
                  <AttachmentWithoutPreview
                    attachment={iDoc}
                    downloadDoc={(params) =>
                      isHistory === undefined
                        ? fetchDocument({ ...params, taskId, taskKey })
                        : fetchHistoricalDocument({
                            ...params,
                            taskId,
                            taskKey,
                          })
                    }
                  />
                  <br />
                </div>
              ))}
          </div>
        )}
        {taskVariables?.fourStopBusinessResult && (
          <div className={classes.detailsWrapperThird}>
            <div className={classes.detailsWrapperFull}>
              <DetailsBlock
                label="document__business__result__title"
                toPrint={toPrintBusinessResult}
              />
            </div>
          </div>
        )}
        {taskVariables?.fourStopBusinessResult?.results &&
          taskVariables.fourStopBusinessResult?.results[0] && (
            <div className={classes.detailsWrapperThird}>
              <QuidTitle textAlign="start" fontSize={20}>
                KYB business compliance
              </QuidTitle>
              <ReactJson
                style={{ fontSize: 12 }}
                src={JSON.parse(
                  taskVariables.fourStopBusinessResult?.results[0]
                )}
                collapsed={true}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                quotesOnKeys={false}
              />
            </div>
          )}
        {taskVariables?.fourStopBusinessResult?.results &&
          taskVariables.fourStopBusinessResult?.results[1] && (
            <div className={classes.detailsWrapperThird}>
              <QuidTitle textAlign="start" fontSize={20}>
                KYB compliance watchlist
              </QuidTitle>
              <ReactJson
                style={{ fontSize: 12 }}
                src={JSON.parse(
                  taskVariables.fourStopBusinessResult?.results[1]
                )}
                collapsed={true}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                quotesOnKeys={false}
              />
            </div>
          )}
        {taskVariables?.fourStopBusinessResult?.results &&
          taskVariables.fourStopBusinessResult?.results[2] && (
            <div className={classes.detailsWrapperThird}>
              <QuidTitle textAlign="start" fontSize={20}>
                KYB adverse media
              </QuidTitle>
              <ReactJson
                style={{ fontSize: 12 }}
                src={JSON.parse(
                  taskVariables.fourStopBusinessResult?.results[2]
                )}
                collapsed={true}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                quotesOnKeys={false}
              />
            </div>
          )}
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectKycCorporateBox
            taskKey={taskKey}
            task={task}
            accept={(request) => acceptTask({ taskId, taskKey, request })}
            reject={(request) => rejectTask({ taskId, taskKey, request })}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default FourStopCorporateVerification;
