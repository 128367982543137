import React from "react";
import {
  createMuiTheme,
  makeStyles,
  createStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

const colorPrimary =
  process.env.REACT_APP_KEYCLOAK_REALM === "quid"
    ? process.env.REACT_APP_QUID_MAIN_COLOR
    : process.env.REACT_APP_KEYCLOAK_REALM === "toonie"
    ? process.env.REACT_APP_TOONIE_MAIN_COLOR
    : process.env.REACT_APP_KEYCLOAK_REALM === "elviria"
    ? process.env.REACT_APP_PROJECT === "elviria"
      ? process.env.REACT_APP_ELVIRIA_MAIN_COLOR
      : process.env.REACT_APP_STRFI_MAIN_COLOR
    : process.env.REACT_APP_COSMO_MAIN_COLOR;

const colorSecondary =
  process.env.REACT_APP_KEYCLOAK_REALM === "quid"
    ? process.env.REACT_APP_QUID_SECONDARY_COLOR
    : process.env.REACT_APP_KEYCLOAK_REALM === "toonie"
    ? process.env.REACT_APP_TOONIE_SECONDARY_COLOR
    : process.env.REACT_APP_KEYCLOAK_REALM === "elviria"
    ? process.env.REACT_APP_PROJECT === "elviria"
      ? process.env.REACT_APP_ELVIRIA_SECONDARY_COLOR
      : process.env.REACT_APP_STRFI_SECONDARY_COLOR
    : process.env.REACT_APP_COSMO_SECONDARY_COLOR;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colorPrimary === undefined ? "#000" : colorPrimary,
    },
    secondary: {
      main: colorSecondary === undefined ? "#000" : colorSecondary,
    },
    text: {
      primary: "#91A1AD",
      secondary: "#527083",
    },
  },
  typography: {
    fontFamily: ["Nunito", "sans-serif"].join(","),
    body1: {
      fontSize: 15,
      fontFamily: ["Nunito", "sans-serif"].join(","),
      display: "block",
      color: "#000",
    },
    body2: {
      fontSize: 12,
      fontFamily: ["Nunito", "sans-serif"].join(","),
      display: "block",
      color: "#000",
    },
    h1: {
      fontSize: 28,
      fontFamily: ["Comfortaa", "sans-serif"].join(","),
      display: "block",
      color: "#000",
    },
    h2: {
      fontSize: 24,
      fontFamily: ["Comfortaa", "sans-serif"].join(","),
      display: "block",
      color: "#000",
    },
    h3: {
      fontSize: 22,
      fontFamily: ["Comfortaa", "sans-serif"].join(","),
      display: "block",
      color: "#000",
    },
    h4: {
      fontSize: 20,
      fontFamily: ["Comfortaa", "sans-serif"].join(","),
      display: "block",
      color: "#000",
    },
    h5: {
      fontSize: 18,
      fontFamily: ["Comfortaa", "sans-serif"].join(","),
      display: "block",
      color: "#000",
    },
    h6: {
      fontSize: 16,
      fontFamily: ["Comfortaa", "sans-serif"].join(","),
      display: "block",
      color: "#000",
    },
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: 1,
      "& .MuiButton-root": {
        width: 185,
        borderRadius: 30,
      },
    },
  })
);

const Theme: React.FC = (props) => (
  <ThemeProvider theme={theme}>
    <div className={useStyles().root}>{props.children}</div>
  </ThemeProvider>
);

export default Theme;
