import {
  VerificationCommentsRequest,
  VerificationDocumentAdditionalRequest,
  VerificationDocumentsRequest,
  VerificationRequest,
} from "./Common";
import { GenericObject } from "./Identity";

export interface Task {
  id: string;
  name: string;
  assignee: string;
  createTime: string;
  endTime: string;
  delegationState: string;
  description: string;
  dueDate: string;
  execution: string;
  priority: number;
  processDefinition: string;
  processInstance: string;
  suspended: boolean;
  totalComments: number;
  totalAttachments: number;
  taskDefinitionKey:
    | "IdentityVerification"
    | "AddressVerification"
    | "EKYCVerification"
    | "FinancialVerification"
    | "FinancialVerificationAccount"
    | "CorporateIdentityVerification"
    | "PersonCorporateIdentityVerification"
    | "PersonCorporateAddressVerification"
    | "PersonCorporateEKYCVerification"
    | "CorporateFinancialVerificationAccount"
    | "CheckCompanyDetails"
    | "UploadCompanyFormationConfirmation"
    | "CorporateCheckAccountDetailsExternal"
    | "CorporateCheckAccountDetailsDirect"
    | "IndividualCheckAccountDetailsExternal"
    | "IndividualCheckAccountDetailsDirect"
    | "fourStopCorporateVerification"
    | "fourStopIndividualVerification"
    | "corporateVerificationErrorHander"
    | "IndividualVerificationError"
    | "AccountOpeningErrorHandler"
    | "AccountCreationError"
    | "AccountOpeningError"
    | "CompanyDetailsError"
    | "CompanyFormationError"
    | "FourStopIndividualVerificationError"
    | "fourStopIndividualDocumentQualifyVerification"
    | "FourStopCorporateVerificationError";
  workflowInformation: Workflow;
}

export interface Workflow {
  source: string;
  userType: string;
  action: string;
  company: string;
}

export interface WorkflowInfo {
  source: string;
  company: string;
  userType: string;
  action: string;
}
export interface DashboardTaskTypes {
  code: string;
  text: string;
}

export interface CompanyTaskTypes {
  code: string;
  text: string;
}

export interface AccountTaskTypes {
  code: string;
  text: string;
}

export interface FetchCompanyTaskTypes {
  taskKey: "CheckCompanyDetails" | "UploadCompanyFormationConfirmation";
}

export interface FetchAccountTaskTypes {
  taskKey:
    | "CorporateCheckAccountDetailsExternal"
    | "CorporateCheckAccountDetailsDirect"
    | "IndividualCheckAccountDetailsExternal"
    | "IndividualCheckAccountDetailsDirect";
}

export interface FetchTasksParams {
  pageNo?: number;
  onlyUnassigned?: boolean;
  taskKey?: string;
  pageSize?: number;
  customerId?: number;
}

export interface FetchTasksSearchParams {}

export interface FetchTaskParams {
  taskId: string;
  isHistory: string;
  taskKey: Task["taskDefinitionKey"];
}

export interface AssignMeTaskParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
}

export interface UnassignMeTaskParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
}

export interface AcceptTaskParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  request?: VerificationRequest;
}

export interface AcceptTaskParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  request?: VerificationRequest;
}
export interface RejectTaskParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  request?: VerificationRequest;
}
export interface FetchDocumentParams {
  taskId: string;
  documentId: string | number;
  taskKey: Task["taskDefinitionKey"];
  startDownload?: boolean;
  fileName: string;
}

export interface FetchAttachmentParams {
  taskId: string;
  commentId: string | number;
  attachmentId: string | number;
  taskKey: Task["taskDefinitionKey"];
  fileName: string;
}

export interface CreateCommentParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  request?: VerificationCommentsRequest;
}

export interface GetCompanyDocumentParams {
  taskId: string;
  documentId: string | number;
  companyId: string | number;
  taskKey: Task["taskDefinitionKey"];
  startDownload?: boolean;
  fileName: string;
}

export interface GetCompanyPersonDocumentParams {
  taskId: string;
  documentId: string | number;
  companyId: string | number;
  personId: string | number;
  personType: string | number;
  taskKey: Task["taskDefinitionKey"];
  startDownload?: boolean;
  fileName: string;
}

export interface CreateCompanyDocumentParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  request?: VerificationDocumentsRequest;
  companyId: string | number;
}

export interface UpdateCompanyDocumentParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  request?: VerificationDocumentsRequest;
  companyId: string | number;
  documentId: string | number;
}

export interface UpdateCompanyPersonDocumentParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  request?: VerificationDocumentsRequest;
  companyId: string | number;
  documentId: string | number;
  personId: string | number;
  personType: string | number;
}

export interface UpdateCompanyDocumentDetailsParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  companyId: string | number;
  documentId: string | number;
  request?: VerificationDocumentAdditionalRequest;
}

export interface UpdateDocumentParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  documentId: string | number;
  request?: VerificationDocumentsRequest;
}

export interface UpdateDocumentDetailsParams {
  taskId: string;
  taskKey: Task["taskDefinitionKey"];
  documentId: string | number;
  request?: VerificationDocumentAdditionalRequest;
}

export interface ResponseTasks {
  total: number;
  tasks: Task[];
}

export const mapHistoryTaskToEndpoint: {
  IdentityVerification: string;
  AddressVerification: string;
  EKYCVerification: string;
  FinancialVerification: string;
  FinancialVerificationAccount: string;
  CorporateIdentityVerification: string;
  PersonCorporateIdentityVerification: string;
  PersonCorporateAddressVerification: string;
  PersonCorporateEKYCVerification: string;
  CorporateFinancialVerificationAccount: string;
  CheckCompanyDetails: string;
  UploadCompanyFormationConfirmation: string;
  CorporateCheckAccountDetailsExternal: string;
  CorporateCheckAccountDetailsDirect: string;
  IndividualCheckAccountDetailsExternal: string;
  IndividualCheckAccountDetailsDirect: string;
  fourStopCorporateVerification: string;
  fourStopIndividualVerification: string;
  corporateVerificationErrorHander: string;
  IndividualVerificationError: string;
  AccountOpeningErrorHandler: string;
  AccountCreationError: string;
  AccountOpeningError: string;
  CompanyDetailsError: string;
  CompanyFormationError: string;
  IndividualIdentityVerificationError: string;
  FourStopIndividualVerificationError: string;
  FourStopCorporateVerificationError: string;
  fourStopIndividualDocumentQualifyVerification: string;
} = {
  IdentityVerification:
    "/workflow/individual/verification/v1/identity/history/tasks",
  AddressVerification:
    "/workflow/individual/verification/v1/addresses/history/tasks",
  FinancialVerification:
    "/workflow/individual/verification/v1/financial/history/tasks",
  FinancialVerificationAccount:
    "/workflow/individual/verification/v1/financial/history/tasks",
  EKYCVerification: "/workflow/individual/verification/v1/ekyc/history/tasks",
  CorporateIdentityVerification:
    "/workflow/corporate/verification/v1/identity/history/tasks",
  PersonCorporateIdentityVerification:
    "/workflow/corporate/verification/v1/people/identity/history/tasks",
  PersonCorporateAddressVerification:
    "/workflow/corporate/verification/v1/people/documents/history/tasks",
  fourStopIndividualDocumentQualifyVerification:
    "/workflow/individual/verification/v1/identity/history/tasks",
  PersonCorporateEKYCVerification:
    "/workflow/corporate/verification/v1/people/ekyc/history/tasks",
  CorporateFinancialVerificationAccount:
    "/workflow/corporate/verification/v1/financial/history/tasks",
  CheckCompanyDetails: "/workflow/companyformation/v1/history/tasks",
  UploadCompanyFormationConfirmation:
    "/workflow/companyformation/v1/history/tasks",
  CorporateCheckAccountDetailsExternal:
    "/workflow/accountopening/v1/history/tasks",
  CorporateCheckAccountDetailsDirect:
    "/workflow/accountopening/v1/history/tasks",
  IndividualCheckAccountDetailsExternal:
    "/workflow/accountopening/v1/history/tasks",
  IndividualCheckAccountDetailsDirect:
    "/workflow/accountopening/v1/history/tasks",
  fourStopCorporateVerification:
    "/workflow/fourstop/corporate/verification/v1/history/tasks",
  fourStopIndividualVerification:
    "/workflow/fourstop/individual/verification/v1/history/tasks",
  corporateVerificationErrorHander:
    "/workflow/corporate/verification/v1/identity/history/tasks",
  IndividualVerificationError:
    "/workflow/individual/verification/v1/identity/history/tasks",
  AccountOpeningErrorHandler: "/workflow/accountopening/v1/history/tasks",
  AccountCreationError: "/workflow/accountopening/v1/history/tasks",
  AccountOpeningError: "/workflow/accountopening/v1/history/tasks",
  CompanyDetailsError: "/workflow/companyformation/v1/history/tasks",
  CompanyFormationError: "/workflow/companyformation/v1/history/tasks",
  IndividualIdentityVerificationError:
    "/workflow/individual/verification/v1/identity/history/tasks",
  FourStopIndividualVerificationError:
    "/workflow/fourstop/individual/verification/v1/history/tasks",
  FourStopCorporateVerificationError:
    "/workflow/fourstop/corporate/verification/v1/history/tasks",
};

export const mapTaskKeyToEndpoint: {
  IdentityVerification: string;
  AddressVerification: string;
  EKYCVerification: string;
  FinancialVerification: string;
  FinancialVerificationAccount: string;
  CorporateIdentityVerification: string;
  PersonCorporateIdentityVerification: string;
  PersonCorporateAddressVerification: string;
  PersonCorporateEKYCVerification: string;
  CorporateFinancialVerificationAccount: string;
  CheckCompanyDetails: string;
  UploadCompanyFormationConfirmation: string;
  CorporateCheckAccountDetailsExternal: string;
  CorporateCheckAccountDetailsDirect: string;
  IndividualCheckAccountDetailsExternal: string;
  IndividualCheckAccountDetailsDirect: string;
  fourStopCorporateVerification: string;
  fourStopIndividualVerification: string;
  corporateVerificationErrorHander: string;
  IndividualVerificationError: string;
  AccountOpeningErrorHandler: string;
  AccountCreationError: string;
  AccountOpeningError: string;
  CompanyDetailsError: string;
  CompanyFormationError: string;
  IndividualIdentityVerificationError: string;
  FourStopIndividualVerificationError: string;
  FourStopCorporateVerificationError: string;
  fourStopIndividualDocumentQualifyVerification: string;
} = {
  IdentityVerification: "/workflow/individual/verification/v1/identity/tasks",
  AddressVerification: "/workflow/individual/verification/v1/addresses/tasks",
  FinancialVerification: "/workflow/individual/verification/v1/financial/tasks",
  FinancialVerificationAccount:
    "/workflow/individual/verification/v1/financial/tasks",
  EKYCVerification: "/workflow/individual/verification/v1/ekyc/tasks",
  CorporateIdentityVerification:
    "/workflow/corporate/verification/v1/identity/tasks",
  PersonCorporateIdentityVerification:
    "/workflow/corporate/verification/v1/people/identity/tasks",
  PersonCorporateAddressVerification:
    "/workflow/corporate/verification/v1/people/addresses/tasks",
  fourStopIndividualDocumentQualifyVerification:
    "/workflow/individual/verification/v1/identity/tasks",
  PersonCorporateEKYCVerification:
    "/workflow/corporate/verification/v1/people/ekyc/tasks",
  CorporateFinancialVerificationAccount:
    "/workflow/corporate/verification/v1/financial/tasks",
  CheckCompanyDetails: "/workflow/companyformation/v1/tasks",
  UploadCompanyFormationConfirmation: "/workflow/companyformation/v1/tasks",
  CorporateCheckAccountDetailsExternal: "/workflow/accountopening/v1/tasks",
  CorporateCheckAccountDetailsDirect: "/workflow/accountopening/v1/tasks",
  IndividualCheckAccountDetailsExternal: "/workflow/accountopening/v1/tasks",
  IndividualCheckAccountDetailsDirect: "/workflow/accountopening/v1/tasks",
  fourStopCorporateVerification:
    "/workflow/fourstop/corporate/verification/v1/tasks",
  fourStopIndividualVerification:
    "/workflow/fourstop/individual/verification/v1/tasks",
  corporateVerificationErrorHander:
    "/workflow/corporate/verification/v1/identity/tasks",
  IndividualVerificationError:
    "/workflow/individual/verification/v1/identity/tasks",
  AccountOpeningErrorHandler: "/workflow/accountopening/v1/tasks",
  AccountCreationError: "/workflow/accountopening/v1/tasks",
  AccountOpeningError: "/workflow/accountopening/v1/tasks",
  CompanyDetailsError: "/workflow/companyformation/v1/tasks",
  CompanyFormationError: "/workflow/companyformation/v1/tasks",
  IndividualIdentityVerificationError:
    "/workflow/individual/verification/v1/identity/tasks",
  FourStopIndividualVerificationError:
    "/workflow/fourstop/individual/verification/v1/tasks",
  FourStopCorporateVerificationError:
    "/workflow/fourstop/corporate/verification/v1/tasks",
};

export const TaskCategories: GenericObject = {
  TASK: "task",
  TASK_SEARCH: "task_search",
  ACCOUNT_TASK: "account_task",
  ACCOUNT_TASK_SEARCH: "account_task_search",
  COMPANY_FORMATION_TASK: "company_formation_task",
  COMPANY_FORMATION_TASK_SEARCH: "company_formation_task_search",
  HISTORIC_TASK: "historic_task",
  HISTORIC_TASK_SEARCH: "historic_task_search",
  HISTORIC_ACCOUNT_TASK: "historic_account_task",
  HISTORIC_ACCOUNT_TASK_SEARCH: "historic_account_task_search",
  HISTORIC_COMPANY_FORMATION_TASK: "history_company_formation_task",
  HISTORIC_COMPANY_FORMATION_TASK_SEARCH:
    "history_company_formation_task_search",
};
