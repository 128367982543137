import React from "react";
import { ColDef } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Button } from "@material-ui/core";
import QuidTitle from "components/atoms/QuidTitle";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import StatusBadge from "components/atoms/StatusBadge";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { setProductStatusByContactId } from "api/product";
import { useHistory } from "react-router";
import DetailButton from "components/atoms/DetailButton";

interface ProductsProps {
  loading: boolean;
  partyId: number;
  contracts?: any;
  isCustomer: boolean;
  refetch: () => Promise<void>;
}

const useStyles = makeStyles(() =>
  createStyles({
    companySpacing: {
      marginTop: 30,
      marginBottom: 30,
    },
    companyContainer: {
      width: "100%",
      marginTop: 30,
      minHeight: 500,
    },
    container: {
      paddingBottom: "60px",
    },
    dateType: {
      display: "flex",
      flexDirection: "column",
      lineHeight: "20px",
    },
    btnContainer: {
      "& .MuiButton-root": { width: "auto !important" },
    },
    status: {
      display: "flex",
      flexDirection: "column",
      lineheiht: "20px",
    },
    actionButtons: {
      width: "60%",
      marginBottom: 30,
      flexGrow: 3,
      display: "flex",
      alignItems: "center",
      "& button": {
        marginLeft: 16,
        paddingLeft: 60,
        paddingRight: 60,
      },
    },
  })
);

const ProductsList: React.FC<ProductsProps> = ({
  partyId,
  contracts,
  isCustomer,
  refetch,
  loading,
}: ProductsProps) => {
  const { t } = useTranslation("customer");
  const classes = useStyles();
  const history = useHistory();

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "product_description",
      headerName: t("name"),
      flex: 2,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "20px",
          }}
        >
          {params?.row?.product?.description}
        </div>
      ),
    },
    {
      field: "status",
      flex: 2,
      headerName: t("status"),
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div className={classes.status}>
          <StatusBadge status={params?.row?.status?.toLowerCase()} />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: t("actions"),
      flex: 2,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div className={classes.btnContainer}>
          {(params?.row?.status?.toLowerCase() === "pending" ||
            params?.row?.status?.toLowerCase() === "cancelled") && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                approveProductByContractId(params?.row?.contractId)
              }
            >
              {t("button_approve")}
            </Button>
          )}
          {(params?.row?.status.toLowerCase() === "completed" ||
            params?.row?.status.toLowerCase() === "succeeded") && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => cancelProductByContractId(params?.row?.contractId)}
            >
              {t("button_cancel")}
            </Button>
          )}
          {params?.row?.status.toLowerCase() === "succeeded" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                completeProductByContractId(params?.row?.contractId)
              }
            >
              {t("button_complete")}
            </Button>
          )}
        </div>
      ),
    },
    {
      field: "",
      headerName: t("details"),
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => (
        <div className={classes.btnContainer}>
          <DetailButton
            onClick={() => {
              if (isCustomer) {
                history.push({
                  pathname: `/customers/${partyId}/products/${params?.row?.contractId}`,
                });
              } else {
                history.push({
                  pathname: `/companies/${partyId}/products/${params?.row?.contractId}`,
                });
              }
            }}
          ></DetailButton>
        </div>
      ),
    },
  ];

  const approveProductByContractId = async (contractId: number) => {
    try {
      await setProductStatusByContactId(contractId, "SUCCEEDED");
      handleSuccessfulMessage("Action received");
      refetch();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const cancelProductByContractId = async (contractId: number) => {
    try {
      await setProductStatusByContactId(contractId, "CANCELLED");
      handleSuccessfulMessage("Action received");
      refetch();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const completeProductByContractId = async (contractId: number) => {
    try {
      await setProductStatusByContactId(contractId, "COMPLETED");
      handleSuccessfulMessage("Action received");
      refetch();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  return (
    <div className={classes.container}>
      <QuidTitle variant="h1">Products</QuidTitle>
      <div className={classes.actionButtons}>
        {isCustomer ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              history.push({
                pathname: `/customers/${partyId}/products/createproduct`,
                state: isCustomer,
              })
            }
          >
            {t("product_create")}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              history.push({
                pathname: `/companies/${partyId}/products/createproduct`,
                state: isCustomer,
              })
            }
          >
            {t("product_create")}
          </Button>
        )}
      </div>
      <div className={classes.companyContainer}>
        <QuidDataGrid
          sortModel={[{ field: "id", sort: "desc" }]}
          rows={contracts ?? []}
          columns={columns}
          disableSelectionOnClick
          rowCount={contracts.length}
          loading={loading}
          pageSize={contracts.length}
        />
      </div>
    </div>
  );
};

export default ProductsList;
