import React from "react";
import FullScreenModal from "components/atoms/FullScreenModal";
import { Grid, Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { formatDate } from "utils";
import { InvestmentDetailResponse } from "@portit/core/entities/Investments";
import { acceptInvestement, rejectInvestement } from "api/investments";
import { useHistory } from "react-router";

interface InvestmentDetailModalProps {
  open: boolean | undefined;
  setOpen: (open: boolean) => void;
  data?: InvestmentDetailResponse;
}

const useStyles = makeStyles((theme) => ({
  butons: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    marginTop: "30px",
  },
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginBottom: "20px",
  },
  actionsCancel: {
    backgroundColor: " #ff0000",
  },
  actionsApprove: {
    backgroundColor: "#32CD32",
  },
}));

const InvestmentDetailModal: React.FC<InvestmentDetailModalProps> = ({
  setOpen,
  open,
  data,
}) => {
  const { t } = useTranslation("investments");
  const classes = useStyles();
  const history = useHistory();

  const investmentDetailsToPrint: InvestmentDetailResponse =
    data?.currency === "USDT" ||
    data?.currency === "BTC" ||
    data?.currency === "SBB"
      ? {
          tokenPrice: data?.tokenPrice
            ? data?.tokenPrice === "0E-18"
              ? "0.00000000000000000"
              : data?.tokenPrice
            : "n/a",
          calculatedPrice: data?.calculatedPrice
            ? data?.calculatedPrice === "0E-18"
              ? "0.00000000000000000"
              : data?.calculatedPrice
            : "n/a",
          feeAmount: data?.feeAmount
            ? data?.feeAmount === "0E-18"
              ? "0.00000000000000000"
              : data?.feeAmount
            : "n/a",
          totalPrice: data?.totalPrice
            ? data?.totalPrice === "0E-18"
              ? "0.00000000000000000"
              : data?.totalPrice
            : "n/a",
          walletId: data?.walletId || "n/a",
          lastUpdated:
            formatDate(data?.lastUpdated, "dd/MM/yyyy HH:mm") || "n/a",
          reference: data?.reference || "n/a",
          id: data?.id || 0,
          partyId: data?.partyId || 0,
          date: "",
          status: "Pending",
          investmentType: "Buy",
          noToken: data?.noToken || 0,
          currency: "EUR",
          agreedTandC: false,
        }
      : {
          tokenPrice: data?.tokenPrice
            ? data?.tokenPrice === "0E-18"
              ? "0.00"
              : data?.tokenPrice.slice(0, -16)
            : "n/a",
          calculatedPrice: data?.calculatedPrice
            ? data?.calculatedPrice === "0E-18"
              ? "0.00"
              : data?.calculatedPrice.slice(0, -16)
            : "n/a",
          feeAmount: data?.feeAmount
            ? data?.feeAmount === "0E-18"
              ? "0.00"
              : data?.feeAmount.slice(0, -16)
            : "n/a",
          totalPrice: data?.totalPrice
            ? data?.totalPrice === "0E-18"
              ? "0.00"
              : data?.totalPrice.slice(0, -16)
            : "n/a",
          walletId: data?.walletId || "n/a",
          lastUpdated:
            formatDate(data?.lastUpdated, "dd/MM/yyyy HH:mm") || "n/a",
          reference: data?.reference || "n/a",
          id: data?.id || 0,
          partyId: data?.partyId || 0,
          date: "",
          status: "Pending",
          investmentType: "Buy",
          noToken: data?.noToken || 0,
          currency: "EUR",
          agreedTandC: false,
        };

  const handleOnClick = (type: string) => {
    if (type === "cancel") {
      rejectInvestement(investmentDetailsToPrint.id);
    } else {
      acceptInvestement(investmentDetailsToPrint.id);
    }
    history.push("/investments");
  };

  return (
    <FullScreenModal open={open} onClose={() => setOpen(false)}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt35}>
            <Typography variant="h1" className={classes.title}>
              {t("modal__title__investment_details")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.mt20}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <DetailsBlock
              label="details_title"
              toPrint={investmentDetailsToPrint}
            />
          </Grid>
        </Grid>
        <div className={classes.butons}>
          <Button
            variant="contained"
            className={classes.actionsApprove}
            onClick={() => handleOnClick("accept")}
          >
            {t("button_accept")}
          </Button>
          <Button
            variant="contained"
            className={classes.actionsCancel}
            onClick={() => handleOnClick("cancel")}
          >
            {t("button_cancel")}
          </Button>
        </div>
      </div>
    </FullScreenModal>
  );
};

export default InvestmentDetailModal;
