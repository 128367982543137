import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function downloadAllUserCsv(): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/parties/v1/customers/csv/users`,
      "account",
      {},
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
