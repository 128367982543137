import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ApproveRejectBox from "../../atoms/ApproveRejectBox";
import { useRouteMatch } from "react-router";
import { ColDef } from "@material-ui/data-grid";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { formatDate } from "utils";
import { useTranslation } from "react-i18next";
import { AccountInfo } from "entities/accounts/Account";
import VerificationTemplate from "templates/VerificationTemplate";
import { useSelector } from "react-redux";
import { getPartiesCountries } from "store/reducers/app.reducer";
import DocumentsProofBlock from "components/molecules/verifications/DocumentsProofBlock";
import jwt_decode from "jwt-decode";
import { acceptTask, rejectTask } from "api/tasks";
import { FinancialData } from "entities/clients/FinancialEntity";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";

const financialVerificationCheck = {
  INFO_CONSISTENT: false,
  ACCOUNT_INFO_CLEAR_SUFFICIENT: false,
  ACCOUNT_INLINE_FINANCIAL_INFO: false,
  INCOMING_OUTGOING_INFO_INLINE_FINANCIAL_INFO: false,
  INCOMING_OUTGOING_INFO_INLINE_ACCOUNT_PURPOSE: false,
  SUPPORTING_DOC_INLINE_FINANCIAL_USAGE: false,
  MARKETING_INFO_VERIFIED: false,
};

const toonieFinancialVerificationCheck = {
  INFO_CONSISTENT: false,
  BUSSINESS_SECTOR_IDENTIFIED: false,
  PRODUCT_AND_SERVICES_OFFERED_IDENTIFIED: false,
  MARKETING_INFO_VERIFIED: false,
};

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

export interface CompanyDetails {
  [key: string]: string;
}

const FinancialVerificationComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
}> = ({ columns, isHistory, task, refetch }) => {
  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();

  const { t } = useTranslation("validations");
  const companyDetail = task?.variables?.company;
  const companyAddressDetail = task?.variables?.address;
  const customerDetail = task?.variables?.customer;
  const companyDocumentProofOfBusiness = task?.documents?.find(
    (d) => d.type === "PROOF_OF_BUSINESS"
  );
  const companyDocumentIncorporationCertificate = task?.documents?.find(
    (d) => d.type === "incorporation__certificate"
  );
  const taskAccountInfo = task?.variables?.accountInfo;
  const taskFinancial = task?.variables?.financial;
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  if (!task) {
    return null;
  }

  const companyToPrint: CompanyDetails = {
    companyName: companyDetail?.companyName || "n/a",
    legalEntityType: companyDetail?.legalEntityType || "n/a",
    legalEntityValue: companyDetail?.legalEntityValue || "n/a",
    companyNumber: companyDetail?.companyNumber || "n/a",
    vatNumber: companyDetail?.vatNumber || "n/a",
    "CONTACT INFORMATIONS": "",
    mobileNumber: customerDetail?.mobileNumber || "n/a",
    email: customerDetail?.email || "n/a",
    INCORPORATION: "",
    incorporationDate: companyDetail?.incorporationDate
      ? formatDate(companyDetail.incorporationDate)
      : "n/a",
    countryOfIncorporation: companyDetail?.incorporationCountry || "n/a",
    ADDRESS: "",
    addressLine1: companyAddressDetail?.addressLine1,
    addressLine2: companyAddressDetail?.addressLine2,
    city: companyAddressDetail?.city,
    state: companyAddressDetail?.state || "",
    country: getCountry(companyAddressDetail?.country) || "",
    postalCode: companyAddressDetail?.postalCode,
  };

  const taskFinancialToPrint: Partial<FinancialData> = {
    fundsSourceType: taskFinancial?.fundsSourceType || "--",
    fundsSourceValue: taskFinancial?.fundsSourceValue || "--",
    companySector: taskFinancial?.companySector || "--",
    companySectorDescription: taskFinancial?.companySectorDescription || "--",
    companySubsector: taskFinancial?.companySubsector || "--",
  };

  const taskMarketingToPrint: Partial<FinancialData> = {
    companyWebsiteUrl: taskFinancial?.companyWebsiteUrl || "--",
    companyMarketingDescription:
      taskFinancial?.companyMarketingDescription || "--",
  };

  const taskAccountInfoToPrint: Partial<AccountInfo> = {
    accountPurpose: taskAccountInfo?.accountPurpose || "n/a",
    paymentOrigin: getCountry(taskAccountInfo?.paymentOrigin) || "",
    paymentDestination: getCountry(taskAccountInfo?.paymentDestination) || "",
    paymentNumberIncomingPerMonth:
      taskAccountInfo?.paymentNumberIncomingPerMonth || "n/a",
    paymentVolumeIncomingPerMonth:
      taskAccountInfo?.paymentVolumeIncomingPerMonth || "n/a",
    paymentNumberOutcomingPerMonth:
      taskAccountInfo?.paymentNumberOutcomingPerMonth || "n/a",
    paymentVolumeOutcomingPerMonth:
      taskAccountInfo?.paymentVolumeOutcomingPerMonth || "n/a",
  };

  return (
    <VerificationTemplate
      refetch={refetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {companyDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={companyToPrint}
              label="corporate__details__title"
            />
          </div>
        )}
        <div className={classes.detailsWrapperThird}>
          {taskFinancialToPrint && (
            <DetailsBlock
              toPrint={taskFinancialToPrint}
              label="financial__details__title"
            />
          )}
          <br />
          {taskAccountInfoToPrint && (
            <DetailsBlock
              toPrint={taskAccountInfoToPrint}
              label="account__details__title"
            />
          )}
          <br />
          {taskMarketingToPrint && (
            <DetailsBlock
              toPrint={taskMarketingToPrint}
              label="marketing__details__title"
            />
          )}
        </div>
        <div className={classes.detailsWrapperThird}>
          {companyDocumentProofOfBusiness && (
            <DocumentsProofBlock
              title={t("document__proofOfBusiness__title")}
              taskKey={taskKey}
              inputId="doc-pobt"
              refetch={refetch}
              doc={companyDocumentProofOfBusiness}
              taskId={taskId}
              isHistory={isHistory ? true : false}
              canReplace={isHistory === undefined && isAssignedToUser}
            />
          )}
        </div>
        {companyDocumentIncorporationCertificate && (
          <div className={classes.detailsWrapperThird}>
            <DocumentsProofBlock
              title={t("document_incorporationCertificate_title")}
              taskKey={taskKey}
              inputId="doc-pic"
              refetch={refetch}
              doc={companyDocumentIncorporationCertificate}
              taskId={taskId}
              isHistory={isHistory ? true : false}
              canReplace={isHistory === undefined && isAssignedToUser}
            />
          </div>
        )}
      </div>
      {process.env.REACT_APP_KEYCLOAK_REALM === "toonie" ? (
        <div>
          {!isHistory && isAssignedToUser && (
            <ApproveRejectBox
              task={task.task}
              checks={toonieFinancialVerificationCheck}
              accept={(request) => acceptTask({ taskId, taskKey, request })}
              reject={(request) => rejectTask({ taskId, taskKey, request })}
            />
          )}
          {isHistory && (
            <ApproveRejectKycBox
              checks={task.checks}
              status={task.result.status}
              reason={task.result.reason}
              description={task.result.description}
            />
          )}
        </div>
      ) : (
        <div>
          {!isHistory && isAssignedToUser && (
            <ApproveRejectBox
              task={task.task}
              checks={financialVerificationCheck}
              accept={(request) => acceptTask({ taskId, taskKey, request })}
              reject={(request) => rejectTask({ taskId, taskKey, request })}
            />
          )}
          {isHistory && (
            <ApproveRejectKycBox
              checks={task.checks}
              status={task.result.status}
              reason={task.result.reason}
              description={task.result.description}
            />
          )}
        </div>
      )}
    </VerificationTemplate>
  );
};

export default FinancialVerificationComp;
