import { ChangeEvent, useEffect, useState } from "react";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import {
  makeStyles,
  createStyles,
  Button,
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { fetchOpportunities } from "api/opportunities";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { Pagination } from "entities/accounts/Pagination";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { Opportunity } from "entities/opportunities/opportunityEntity";
import { useTranslation } from "react-i18next";
import DetailButton from "components/atoms/DetailButton";
import { useHistory } from "react-router";
import { getInvestmentsOpportunitiesStatus } from "store/reducers/app.reducer";
import { useSelector } from "react-redux";
import { InvestmentsDataset } from "entities/investments/InvestmentsDatasets";
import SearchIcon from "components/atoms/icons/SearchIcon";

const useStyles = makeStyles((theme) =>
  createStyles({
    addressSpacing: {
      marginTop: 30,
      marginBottom: 30,
    },
    accountContainer: {
      width: "100%",
      marginTop: 30,
      minHeight: 500,
    },
    container: {
      paddingBottom: "60px",
    },
    btnWrapper: {
      display: "flex",
      justifyContent: "end",
    },
    searchBox: {
      width: "37px",
      height: "37px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "30px",
      backgroundColor: theme.palette.secondary.main,
    },
  })
);

export interface Filters {
  status: string;
}

const OpportunitiesList = () => {
  const { t } = useTranslation("opportunities");
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [opportunities, setOpportunities] = useState([] as Array<Opportunity>);
  const [pagination, setPagination] = useState({} as Pagination);
  const [filters, setFilters] = useState({} as Filters);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const opportunitiesList = async () => {
      setLoading(true);
      try {
        const res = await fetchOpportunities({
          page,
          size: TABLE_PAGE_SIZE,
          ...(search && { name: search }),
          ...(filters.status && { status: filters.status }),
        });
        setPagination(res.pagination);
        setOpportunities(res.opportunityList);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    void opportunitiesList();
  }, [page, filters, search]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  const opportunitiesStatus = useSelector(getInvestmentsOpportunitiesStatus);

  const columns: ColDef[] = [
    {
      flex: 1,
      field: "id",
      headerName: "ID",
    },
    {
      flex: 1,
      field: "name",
      headerName: "name",
    },
    {
      flex: 1,
      field: "status",
      headerName: "status",
    },
    {
      field: "",
      headerName: t("details__label__details"),
      renderCell: (params) => (
        <DetailButton to={`/opportunities/${params?.row?.id}`} />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.btnWrapper}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/opportunities/create")}
        >
          {t("opportunity__create_button")}
        </Button>
      </div>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={4}>
          <TextField
            label={t("filter__search")}
            variant="outlined"
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearch(e.target.value)
            }
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={classes.searchBox}>
                    <SearchIcon color="#fff" />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t("filter__status")}
            variant="outlined"
            type="string"
            fullWidth
            select
            value={filters.status}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilters((state) => ({
                ...state,
                status: e.target.value,
              }))
            }
          >
            <MenuItem value="">{t("all_opportunities")}</MenuItem>
            {opportunitiesStatus?.map(
              (option: InvestmentsDataset, index: number) => (
                <MenuItem key={`${option}-${index}`} value={option.code}>
                  {option.text}
                </MenuItem>
              )
            )}
          </TextField>
        </Grid>
      </Grid>
      <div className={classes.accountContainer}>
        <QuidDataGrid
          disableSelectionOnClick
          onPageChange={onPageChange}
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={opportunities}
          rowCount={pagination?.total_entries}
        />
      </div>
    </div>
  );
};

export default OpportunitiesList;
