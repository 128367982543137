import { Grid, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { Link, RouteComponentProps } from "react-router-dom";
import clsx from "clsx";
import QuidTitle from "components/atoms/QuidTitle";
import TransactionDetail from "components/organisms/accounts/TransactionDetail";
import { handleFailure } from "resHandlers";
import { firstCapitalLetter } from "utils";
import AccountStatusBadge, {
  AccountBadgeStatuses,
} from "components/organisms/accounts/AccountStatusBadge";
import { MoneyFormatter } from "../shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { fetchBalanceByBalanceId } from "api/accounts";
import { BalanceResponse } from "entities/accounts/Balance";

const useStyles = makeStyles((theme) => ({
  accountsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
  },
  status: {
    display: "flex",
    fontSize: 14,
    flexDirection: "row",
    paddingRight: 24,
  },
  infoContainer: {
    width: "100%",
    height: 60,
    display: "flex",
    fontSize: 18,
    flexDirection: "row",
    background: "#F3F3F3",
    alignItems: "center",
    color: "#37383C",
    justifyContent: "space-between",
    "& a": {
      color: theme.palette.secondary.main,
      textDecoration: "underline",
      paddingLeft: 24,
    },
    "& span": {
      paddingLeft: 24,
    },
  },
  detailContainer: {
    background: "#FAFAFA",
    width: "100%",
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  flex1: {
    flex: 1,
  },
  flexContainer: {
    display: "flex",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexColumn: {
    flexDirection: "column",
  },
  mt16: {
    marginTop: 16,
  },
  pl24: {
    paddingLeft: 24,
  },
  pr8: {
    paddingRight: 8,
  },
  pl8: {
    paddingLeft: 8,
  },
  pr24: {
    paddingRight: 24,
  },
  mt32: {
    marginTop: 32,
  },
  mb32: {
    marginBottom: 32,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  red: {
    background: "#FB4C4C",
  },
  green: {
    background: "#94E60F",
  },
  fullHeight: {
    height: "100%",
  },
  actionBtn: {
    height: 52,
    "&.MuiButton-root": {
      width: "90%",
    },
  },
  taskLink: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
}));

export interface AccountDetailsToPrint {
  creation_date: string;
  account_holder: string;
  type: string;
  currency: string;
  iban: string;
  wallet_id: string;
}

export interface BalanceInfo {
  total_balance: string;
  balanceId: number;
  available_balance: string;
}

const AccountBalanceDetail: React.FC<
  RouteComponentProps<{ accountId: string; id: string }>
> = ({ match }) => {
  const { t } = useTranslation("account");
  const classes = useStyles();
  const balanceId = parseInt(match.params.id, 10);
  const [details, setDetails] = useState({} as BalanceResponse);

  const accountDetailsToPrint: AccountDetailsToPrint = {
    creation_date: details?.creation_date || "n/a",
    account_holder: details?.account_holder || "n/a",
    type: details?.type || "n/a",
    currency: details?.currency || "n/a",
    iban: details?.iban || "n/a",
    wallet_id: details?.wallet_id || "n/a",
  };

  const balancesToPrint: BalanceInfo = {
    balanceId,
    total_balance:
      MoneyFormatter(
        details?.current_amount,
        details?.currency as DineroFactory.Currency
      ) || "n/a",
    available_balance: MoneyFormatter(
      details?.available_amount,
      details?.currency as DineroFactory.Currency
    ),
  };

  useEffect(() => {
    const fetchBalanceDetails = async () => {
      try {
        const balanceRes = await fetchBalanceByBalanceId({
          balance_id: balanceId,
        });
        setDetails(balanceRes);
      } catch (err: any) {
        handleFailure(err);
      }
    };
    fetchBalanceDetails();
  }, []);

  const margins = clsx(classes.pl24, classes.pr24, classes.mt32, classes.mb32);

  return (
    <MainTemplate>
      <div className={classes.accountsContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("balance__details__title")}</QuidTitle>
          </Grid>
        </Grid>
        <div className={classes.infoContainer}>
          <>
            <Link to={`/customers/${details?.account_id}`}>
              {details?.account_holder}
            </Link>
          </>
          <div className={classes.status}>
            <AccountStatusBadge
              status={details?.status as AccountBadgeStatuses}
            />
            <span>{firstCapitalLetter(details?.status)}</span>
          </div>
        </div>
        <div className={classes.detailContainer}>
          <Grid container className={margins} justify={"center"}>
            <Grid item xs={2} />
            <Grid item xs={4}>
              <DetailsBlock
                label="account__details__menu__title"
                toPrint={accountDetailsToPrint}
              />
            </Grid>
            <Grid item xs={4}>
              <DetailsBlock
                label="account__details__balances"
                toPrint={balancesToPrint}
              />
            </Grid>
          </Grid>
        </div>
        <TransactionDetail balanceId={balanceId} />
      </div>
    </MainTemplate>
  );
};

export default AccountBalanceDetail;
