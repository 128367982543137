import { Grid } from "@material-ui/core";
import { ColDef } from "@material-ui/data-grid";
import { fetchCryptoWalletManagementTransactionsByPartyId } from "@portit/core/api/Crypto";
import { WalletTransactionsResponse } from "@portit/core/entities/Crypto";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import QuidTitle from "components/atoms/QuidTitle";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";

const CryptoWalletTransactions: React.FC<
  RouteComponentProps<{ id: string }>
> = ({ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("crypto");
  const [loading, setLoading] = useState(false);
  const partyId = parseInt(match.params.id, 10);

  const [walletTransactions, setWalletTransactions] = useState<
    WalletTransactionsResponse[]
  >();

  useEffect(() => {
    const getWalletTransactions = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCryptoWalletManagementTransactionsByPartyId(
          partyId
        );
        setWalletTransactions(res?.walletTransactions);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getWalletTransactions();
  }, [dispatch]);

  const columns: ColDef[] = [
    { field: "id", headerName: t("listTable__header_name__id"), flex: 1 },
    {
      field: "currency",
      headerName: t("listTable__header_name__currency"),
      flex: 1,
    },
    {
      field: "uintAmount",
      headerName: t("listTable__header_name__uint_amount"),
      flex: 1,
    },
  ];

  return (
    <MainTemplate>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("cryptowallet__transactions__page__title")}</QuidTitle>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <QuidDataGrid
          disableSelectionOnClick
          sortModel={[{ field: "id", sort: "desc" }]}
          loading={loading}
          columns={columns}
          rows={walletTransactions || []}
          rowCount={walletTransactions?.length}
        />
      </Grid>
    </MainTemplate>
  );
};

export default CryptoWalletTransactions;
