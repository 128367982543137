import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ApproveRejectBox from "components/atoms/ApproveRejectBox";
import { useRouteMatch } from "react-router";
import { ColDef } from "@material-ui/data-grid";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { getPartiesCountries } from "store/reducers/app.reducer";
import IdentityDocBlock from "components/molecules/verifications/IdentityDocBlock";
import SelfieDocBlock from "components/molecules/verifications/SelfieDocBlock";
import {
  acceptTask,
  rejectTask,
  updateDocument,
  updateDocumentDetails,
} from "api/tasks";
import VerificationTemplate from "templates/VerificationTemplate";
import { formatDate } from "utils";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { Grid } from "@material-ui/core";
import { VerificationRequest } from "entities/tasks/Common";
import { Document } from "entities/clients/DocumentEntity";
import { acceptDocumentCustomer, rejectDocumentCustomer } from "api/customers";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

const identityVerificationChecks = {
  DOCUMENT_READABLE: false,
  DOCUMENT_VALID: false,
  DETAILS_MATCH: false,
  SELFIE_MATCH: false,
  DOCUMENT_GENUINE: false,
};

export interface IndividualDetails {
  [key: string]: string;
}

const IndividualIdentityVerificationComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
}> = ({ columns, isHistory, task, refetch }) => {
  const classes = useStyles();
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();
  const customerDetail = task?.variables?.customer;
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;
  const customerDocumentsIdentity = task?.documents?.filter(
    (d) => d.type === "ID_DOCUMENT"
  );
  const customerDocumentsSelfie = task?.documents?.find(
    (d) => d.type === "SELFIE"
  );
  const documents = customerDocumentsIdentity?.concat(
    customerDocumentsSelfie || []
  );

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  const customerToPrint: IndividualDetails = {
    userId: customerDetail?.id,
    name: customerDetail?.name,
    surname: customerDetail?.surname,
    dateOfBirth: formatDate(customerDetail?.birthDate),
    countryOfBirth: getCountry(customerDetail?.countryOfBirth),
    nationality: getCountry(customerDetail?.nationality),
    "CONTACT INFORMATIONS": "",
    phone: `${customerDetail?.prefix} ${customerDetail?.mobileNumber}`,
    email: customerDetail?.email,
    "OTHER INFORMATIONS": "",
    mobileNumberVerified: customerDetail?.mobileNumberVerified ? "Yes" : "No",
    emailVerified: customerDetail?.emailVerified ? "Yes" : "No",
  };

  const onClickApprove = async (request: VerificationRequest) => {
    documents?.map((document?: Document) => {
      if (document) {
        acceptDocumentCustomer(
          (document.partyId as unknown) as string,
          (document.id as unknown) as string
        );
      }
    });
    acceptTask({ taskId, taskKey, request });
  };

  const onClickReject = async (request: VerificationRequest) => {
    documents?.map((document?: Document) => {
      if (document) {
        rejectDocumentCustomer(
          (document.partyId as unknown) as string,
          (document.id as unknown) as string
        );
      }
    });
    rejectTask({ taskId, taskKey, request });
  };

  if (!task) {
    return null;
  }
  return (
    <VerificationTemplate
      refetch={fetch}
      task={task}
      columns={columns}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {customerDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={customerToPrint}
              label="individual__details__title"
            />
          </div>
        )}
        {customerDocumentsIdentity?.length && (
          <div className={classes.detailsWrapperThird}>
            <IdentityDocBlock
              downloadDoc={(params) =>
                isHistory === undefined
                  ? fetchDocument({ ...params, taskId, taskKey })
                  : fetchHistoricalDocument({ ...params, taskId, taskKey })
              }
              updateDoc={(params) => updateDocument({ ...params, taskKey })}
              updateDocDetails={(params) =>
                updateDocumentDetails({ ...params, taskKey })
              }
              refetch={refetch}
              docs={customerDocumentsIdentity}
              taskId={taskId}
              isAssignedToUser={isAssignedToUser}
            />
          </div>
        )}
        {customerDocumentsSelfie && (
          <div className={classes.detailsWrapperThird}>
            <SelfieDocBlock
              taskKey={taskKey}
              refetch={refetch}
              taskId={taskId}
              doc={customerDocumentsSelfie}
              isAssignedToUser={isAssignedToUser}
              downloadDoc={(params) =>
                isHistory === undefined
                  ? fetchDocument({ ...params, taskId, taskKey })
                  : fetchHistoricalDocument({ ...params, taskId, taskKey })
              }
            />
          </div>
        )}
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectBox
            checks={identityVerificationChecks}
            task={task}
            docs={customerDocumentsIdentity}
            accept={(request) => onClickApprove(request)}
            reject={(request) => onClickReject(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task?.checks}
          status={task?.result?.status}
          reason={task?.result?.reason}
          description={task?.result?.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default IndividualIdentityVerificationComp;
