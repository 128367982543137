import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  statusBadge: {
    width: "14px",
    height: "14px",
    marginRight: "5px",
    borderRadius: "30px",
    "&-rejected": {
      backgroundColor: "#FB4C4C",
    },
    "&-accepted": {
      backgroundColor: "#A1F714",
    },
    "&-failed": {
      backgroundColor: "#FB4C4C",
    },
    "&-doing": {
      backgroundColor: "#FFCF23",
    },
    "&-pending": {
      backgroundColor: "#FFCF23",
    },
    "&-pending_to_review": {
      backgroundColor: "#FF6F0F",
    },
    "&-done": {
      backgroundColor: "#A1F714",
    },
    "&-CURRENT": {
      backgroundColor: "#A1F714",
    },
    "&-completed": {
      backgroundColor: "#A1F714",
    },
    "&-deleted": {
      backgroundColor: "#000000",
    },
    "&-cancelled": {
      backgroundColor: "#FB4C4C",
    },
    "&-succeeded": {
      backgroundColor: "#A1F714",
    },
    "&-PENDING": {
      backgroundColor: "#FFCF23",
    },
    "&-REJECTED": {
      backgroundColor: "#FB4C4C",
    },
    "&-DOING": {
      backgroundColor: theme.palette.secondary.main,
    },
    "&-pending_customer": {
      backgroundColor: "#ffa500",
    },
    "&-DONE": {
      backgroundColor: "#A1F714",
    },
    "&-PROCESSING": {
      backgroundColor: "#A1F714",
    },
    "&-SUCCEEDED": {
      backgroundColor: "#A1F714",
    },
    "&-FAILED": {
      backgroundColor: "#FB4C4C",
    },
    "&-COMPLETED": {
      backgroundColor: "#A1F714",
    },
    "&-CANCELLED": {
      backgroundColor: "#FB4C4C",
    },
  },
  statusBadgeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
}));

export interface StatusProps {
  status?:
    | "pending"
    | "accepted"
    | "done"
    | "rejected"
    | "doing"
    | "failed"
    | "deleted"
    | "pending_customer"
    | "CURRENT"
    | "PENDING"
    | "DOING"
    | "DONE"
    | "REJECTED"
    | "PROCESSING"
    | "SUCCEEDED"
    | "FAILED"
    | "COMPLETED"
    | "CANCELLED";
  viewStatusText?: boolean;
}

const StatusBadge: React.FC<StatusProps> = ({
  status,
  viewStatusText,
}: StatusProps) => {
  const { t } = useTranslation("componenets");
  const classes = useStyles();
  const badgeCn = clsx(classes.statusBadge, `${classes.statusBadge}-${status}`);
  return (
    <div className={classes.statusBadgeContainer}>
      <div className={badgeCn} />
      {(viewStatusText ||
        viewStatusText === undefined ||
        viewStatusText === null) &&
        t(`${status}`)}
    </div>
  );
};

export default StatusBadge;
