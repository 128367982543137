import React, { useEffect, useState } from "react";
import { Button, makeStyles, MenuItem, Typography } from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import { ServerFailure } from "features/core/Failure";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import {
  fetchCryptoBeneficiariesManagementByPartyId,
  fetchCryptoWalletsManagement,
  postCryptoPaymentsManagement,
} from "@portit/core/api/Crypto";
import {
  BalanceDetailResponse,
  BeneficiaryDetailResponse,
  BeneficiaryWalletDetailResponse,
  WalletDetailResponse,
} from "@portit/core/entities/Crypto";
import Loader from "components/atoms/icons/Loader";

const useStyles = makeStyles((theme) => ({
  container: { backgroundColor: "#FAFAFA" },
  containerInner: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  column4: { width: "calc(100% / 3 - 30px)", padding: "0px 15px" },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  containerInnerButtons: {
    justifyContent: "center",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 60,
  },
  checkText: {
    textAlign: "center",
    paddingTop: 13,
    fontFamily: "Nunito",
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: 18,
  },
  accountDetailsWrapper: {
    background: "#fff",
    padding: 15,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginTop: 15,
    cursor: "pointer",
  },
}));

interface FormValues {
  walletIdFrom: number;
  beneficiaryWalletIdTo: number;
  amount: string;
  network: string;
  currency: string;
  smartContractId: number;
  reason: string;
}

const CryptoPaymentCreate: React.FC = () => {
  const { t } = useTranslation("payments");

  const [loading, setLoading] = useState({
    fromWallet: false,
    toBeneficiary: false,
  });

  const [fromWallets, setFromWallets] = useState<WalletDetailResponse[]>([]);
  const [fromWallet, setFromWallet] = useState<WalletDetailResponse>();

  const [toBeneficiaries, setToBeneficiaries] = useState<
    BeneficiaryDetailResponse[]
  >([]);

  const [
    toBeneficiary,
    setToBeneficiary,
  ] = useState<BeneficiaryDetailResponse>();

  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultValues = {
    currency: "",
    amount: 0,
    reason: "",
    walletIdFrom: 0,
    beneficiaryWalletIdTo: 0,
    network: "",
    smartContractId: 0,
    decimalPlaces: 0,
    tokenId: "",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  const onResetForm = () => {
    reset(defaultValues);
    setFromWallet(undefined);
    setToBeneficiary(undefined);
    setLoading({
      fromWallet: false,
      toBeneficiary: false,
    });
  };

  useEffect(() => {
    const getCryptoWalletsManagement = async (): Promise<void> => {
      setLoading({
        fromWallet: true,
        toBeneficiary: false,
      });
      try {
        const res = await fetchCryptoWalletsManagement({
          size: 99999,
          page: 0,
        });
        setFromWallets(res?.wallets);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading({
          fromWallet: false,
          toBeneficiary: false,
        });
      }
    };

    void getCryptoWalletsManagement();
  }, []);

  useEffect(() => {
    const getCryptoWalletBeneficiaries = async (): Promise<void> => {
      if (fromWallet?.id !== undefined) {
        setLoading({
          fromWallet: false,
          toBeneficiary: true,
        });
        try {
          const res = await fetchCryptoBeneficiariesManagementByPartyId(
            fromWallet?.partyId
          );
          setToBeneficiaries(res?.beneficiaries);
        } catch (err: any) {
          const message =
            err instanceof ServerFailure
              ? (err as ServerFailure)?.error?.message
              : (err as NetworkFailure)?.message;
          dispatch(
            promptsSlice.actions.openSnackbar({
              message,
              type: "error",
            })
          );
        } finally {
          setLoading({
            fromWallet: false,
            toBeneficiary: false,
          });
        }
      }
    };

    void getCryptoWalletBeneficiaries();
  }, [fromWallet]);

  const onFormSubmit = async (values: FormValues) => {
    if (fromWallet?.id !== undefined && toBeneficiary?.name !== undefined) {
      try {
        const fromBalance = fromWallet.balances.filter(
          (balance: BalanceDetailResponse) =>
            balance.currency === values.currency
        )[0];

        const toBalance = toBeneficiary.wallets?.filter(
          (wallet: BeneficiaryWalletDetailResponse) =>
            wallet?.currency === values.currency
        )[0];

        if (fromBalance?.smartContractId === null) {
          dispatch(
            promptsSlice.actions.openSnackbar({
              message:
                "smartContractId is undefined. Ask to the team for verify the debit wallet.",
              type: "warning",
            })
          );
          return;
        }

        await postCryptoPaymentsManagement({
          walletIdFrom: fromWallet?.id,
          beneficiaryWalletIdTo: toBalance?.beneficiaryWalletId ?? 0,
          amount: values?.amount,
          network: fromBalance.network ?? "",
          currency: values?.currency,
          smartContractId: fromBalance?.smartContractId ?? 0,
          reason: values?.reason,
          decimalPlaces: 0,
          tokenId: "",
        });
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: t("payment__success__message"),
            type: "success",
          })
        );
        // history.push("/payments/outgoing");
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    }
  };

  const fromWalletDetails = {
    address: fromWallet?.walletAddress || "n/a",
    name:
      fromWallet?.customer?.type === "INDIVIDUAL"
        ? fromWallet?.customer?.surname
        : "",
  };

  const toBeneficiaryDetails = {
    name: toBeneficiary?.name,
  };

  return (
    <MainTemplate>
      <QuidTitle>{t("internal__transfer__page__title")}</QuidTitle>
      <div className={classes.container}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormProvider {...methods}>
            <div className={classes.containerInner}>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__transfer__head__from__label")}
                </QuidTitle>
                {loading.fromWallet ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  fromWallet?.id === undefined &&
                  fromWallets?.map(
                    (wallet: WalletDetailResponse, index: number) => (
                      <div
                        key={index}
                        onClick={() => {
                          setFromWallet(wallet);
                        }}
                        className={classes.accountDetailsWrapper}
                      >
                        <div>
                          <Typography variant="body1">
                            {wallet.partyId}
                            <br />
                            <small>{wallet.walletAddress}</small>
                          </Typography>
                        </div>
                      </div>
                    )
                  )
                )}
                {fromWallet && (
                  <DetailsBlock label="" toPrint={fromWalletDetails} />
                )}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__transfer__head__to__label")}
                </QuidTitle>
                {loading.toBeneficiary ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  fromWallet?.id !== undefined &&
                  toBeneficiary === undefined &&
                  toBeneficiaries?.map(
                    (beneficiary: BeneficiaryDetailResponse, index: number) => (
                      <div
                        key={index}
                        onClick={() => {
                          setToBeneficiary(beneficiary);
                        }}
                        className={classes.accountDetailsWrapper}
                      >
                        <div>
                          <Typography variant="body1">
                            {beneficiary.name}
                            <br />
                            <small>{beneficiary.name}</small>
                          </Typography>
                        </div>
                      </div>
                    )
                  )
                )}
                {toBeneficiary && (
                  <DetailsBlock label="" toPrint={toBeneficiaryDetails} />
                )}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__transfer__info")}
                </QuidTitle>
                {toBeneficiary && (
                  <>
                    <QuidTextField
                      rules={{
                        required: t("currency__required") as string,
                      }}
                      name="currency"
                      defaultValues={defaultValues}
                      label={t("internal__transfer__currency__placeholder")}
                      textFieldProps={{ select: true, fullWidth: true }}
                    >
                      {fromWallet?.balances?.map(
                        (frombalance: BalanceDetailResponse, index: number) => {
                          return (
                            <MenuItem
                              key={`${frombalance.currency}-${index}`}
                              value={frombalance.currency}
                            >
                              {frombalance?.currency}
                            </MenuItem>
                          );
                        }
                      )}
                    </QuidTextField>
                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      name="amount"
                      rules={{
                        required: t(
                          "internal__transfer__form__amount__required"
                        ) as string,
                      }}
                      label={t("internal__transfer__form__amount__label")}
                      defaultValues={defaultValues}
                    />
                    <QuidTextField
                      textFieldProps={{ fullWidth: true }}
                      rules={{
                        required: t("reason__required") as string,
                      }}
                      name="reason"
                      label={t("internal__transfer__form__reference__label")}
                      defaultValues={defaultValues}
                    />
                  </>
                )}
              </div>
            </div>
            <div className={classes.containerInnerButtons}>
              <Button variant="contained" color="primary" type="submit">
                {t("internal__transfer__create__button")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={onResetForm}
              >
                {t("internal__transfer__reset__button")}
              </Button>
            </div>
          </FormProvider>
        </form>
      </div>
    </MainTemplate>
  );
};

export default CryptoPaymentCreate;
