import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router";
import { ColDef } from "@material-ui/data-grid";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { getPartiesCountries } from "store/reducers/app.reducer";
import AttachmentWithoutPreview from "components/molecules/verifications/AttachmentWithoutPreview";
import { acceptTask, rejectTask } from "api/tasks";
import VerificationTemplate from "templates/VerificationTemplate";
import { formatDate } from "utils";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import ApproveRejectKycIndividualBox from "components/atoms/ApproveRejectKyIndividualBox";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

export interface IndividualDetails {
  [key: string]: string;
}

const IndividualEKycVerificationComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
}> = ({ columns, isHistory, task, refetch }) => {
  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();

  const identityDocs = task?.documents?.filter((d) => d.type === "ID_DOCUMENT");
  const customerDetail = task?.variables?.customer;
  const customerAddressDetail = task?.variables?.address;
  const customerProofOfAddressDocument = task?.documents?.find(
    (d) => d.type === "PROOF_OF_ADDRESS"
  );
  const customerDocumentsSelfie = task?.documents?.find(
    (d) => d.type === "SELFIE"
  );
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  if (!task) {
    return null;
  }

  const customerToPrint: IndividualDetails = {
    userId: customerDetail?.id,
    name: customerDetail?.name,
    surname: customerDetail?.surname,
    dateOfBirth: formatDate(customerDetail?.birthDate),
    countryOfBirth: getCountry(customerDetail?.countryOfBirth),
    nationality: getCountry(customerDetail?.nationality),
    "CONTACT INFORMATIONS": "",
    phone: `${customerDetail?.prefix} ${customerDetail?.mobileNumber}`,
    email: customerDetail?.email,
    "OTHER INFORMATIONS": "",
    mobileNumberVerified: customerDetail?.mobileNumberVerified ? "Yes" : "No",
    emailVerified: customerDetail?.emailVerified ? "Yes" : "No",
  };

  const customerAddressToPrint: IndividualDetails = {
    addressLine1: customerAddressDetail?.addressLine1,
    addressLine2: customerAddressDetail?.addressLine2 || "--",
    city: customerAddressDetail?.city,
    country: getCountry(customerAddressDetail.country),
    postalCode: customerAddressDetail?.postalCode,
  };

  return (
    <VerificationTemplate
      refetch={refetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {customerDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={customerToPrint}
              label="individual__details__title"
            />
            {customerDocumentsSelfie && (
              <AttachmentWithoutPreview
                attachment={customerDocumentsSelfie}
                downloadDoc={(params) =>
                  fetchDocument({ ...params, taskId, taskKey })
                }
              />
            )}
          </div>
        )}
        {customerAddressDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={customerAddressToPrint}
              label="individual__address__details__title"
            />
          </div>
        )}
        <div className={classes.detailsWrapperThird}>
          <DetailsBlock label="document__proofOfAddressPreview__title" />
          {customerProofOfAddressDocument && (
            <div>
              <AttachmentWithoutPreview
                attachment={customerProofOfAddressDocument}
                downloadDoc={(params) =>
                  isHistory === undefined
                    ? fetchDocument({ ...params, taskId, taskKey })
                    : fetchHistoricalDocument({ ...params, taskId, taskKey })
                }
              />
              <br />
            </div>
          )}
          <DetailsBlock label="document__proofOfIdentity__title" />
          {identityDocs?.length &&
            identityDocs?.map((iDoc) => (
              <div>
                <AttachmentWithoutPreview
                  attachment={iDoc}
                  downloadDoc={(params) =>
                    isHistory === undefined
                      ? fetchDocument({ ...params, taskId, taskKey })
                      : fetchHistoricalDocument({ ...params, taskId, taskKey })
                  }
                />
                <br />
              </div>
            ))}
        </div>
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectKycIndividualBox
            taskKey={taskKey}
            task={task}
            accept={() => acceptTask({ taskId, taskKey })}
            reject={() => rejectTask({ taskId, taskKey })}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default IndividualEKycVerificationComp;
