import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoggedIn } from "store/reducers/app.reducer";
import {
  fetchAccountDatasets,
  fetchPartiesDatasets,
  fetchInvestmentsDatasets,
} from "./store/actions/dataset";

export interface QuidBootstrapProps {
  children?: ReactNode | ReactNode[];
}

const QuidBootstrap: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const userIsLoggedIn = useSelector(getIsLoggedIn);
  useEffect(() => {
    if (userIsLoggedIn) {
      switch (process.env.REACT_APP_KEYCLOAK_REALM) {
        case "quid":
          dispatch(fetchAccountDatasets() as any);
          dispatch(fetchPartiesDatasets() as any);
          break;

        case "toonie":
          dispatch(fetchAccountDatasets() as any);
          dispatch(fetchPartiesDatasets() as any);
          break;

        case "elviria":
          dispatch(fetchPartiesDatasets() as any);
          dispatch(fetchAccountDatasets() as any);
          dispatch(fetchInvestmentsDatasets() as any);
          break;

        case "strfiinvest":
          dispatch(fetchPartiesDatasets() as any);
          dispatch(fetchAccountDatasets() as any);
          dispatch(fetchInvestmentsDatasets() as any);
          break;

        case "vaffa":
        case "ats":
          dispatch(fetchPartiesDatasets() as any);
          break;
      }
    }
  }, [userIsLoggedIn]);
  return <>{children}</>;
};

export default QuidBootstrap;
