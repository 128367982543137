import React, { useEffect, useState, ChangeEvent } from "react";
import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import QuidTextField from "components/atoms/QuidTextField";
import { useHistory } from "react-router";
import {
  Detail,
  KeyFact,
  Opportunity,
} from "entities/opportunities/opportunityEntity";
import { updateImage, updateLogo, updateMediImage } from "api/opportunities";
import QuidUploadField from "components/atoms/QuidUploadField";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import OpportunityImage from "components/molecules/verifications/OpportunityImage";
import { getInvestementReferenceData } from "api/investments";
import QuidTitle from "components/atoms/QuidTitle";
import { format } from "date-fns";
import QuidTextareaField from "components/atoms/QuidTextareaField";
import { ToggleButton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  gridContainerInfo: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,

    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  jsonFormWrapper: {
    width: "200px",
    marginTop: 24,
  },
  updateBtn: { display: "block", margin: "20px auto 70px auto" },
  alignImage: { display: "flex", margin: "auto" },
  gridContainerImages: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
    justifyContent: "center",
    alignItems: "end",
    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  gridContainerDataFile: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
    justifyContent: "center",
    alignItems: "end",
    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  containerJson: {
    marginBottom: "70px",
  },
  gridContainerJson: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
    display: "flex",
    flexDirection: "column",
  },
  gridContainerJsonRow: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
  },
  sectionTitleBox: {
    cursor: "pointer",
    border: "1px solid #efefef",
    padding: "10px",
    marginBottom: "16px",
  },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginBottom: "5px",
  },

  tableRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonsCn: {
    display: "flex",
    gap: 16,
  },
}));

type FormValues = {
  name: string;
  currency: string;
  assetClass: string;
  step: string;
  minRequested: string;
  maxRequested: string;
  horizon: string;
  stage: string;
  sector: string;
  tokenPrice: string;
  valuation: string;
  shortDescription: string;
  longDescription: string;
  startDate: Date | string;
  endDate: Date | string;
  noToken: number;
  bigImageId: number;
  logoImageId: number;
  details: Detail[];
  keyFacts: KeyFact[];
  beneficiaryWalletsWalletId: string;
  beneficiaryWalletsCurrency: string;
  smartContractName?: string;
  smartContractTemplate?: string;
  smartContractAmount?: string;
  smartContractDescription?: string;
  smartContractPrecision?: number;
  smartContractAddress?: string;
  smartContractNetwork?: string;
};

export interface OpportunitiesDetailsProps {
  opportunity?: Opportunity;
  opportunityId?: number;
  mode: string;
  onFormSubmit: any;
  isLoading: boolean;
}

const OpportunitiesCreateOrEdit: React.FC<OpportunitiesDetailsProps> = ({
  opportunity,
  opportunityId,
  mode,
  onFormSubmit,
  isLoading,
}: OpportunitiesDetailsProps) => {
  const { t } = useTranslation("opportunities");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [sectors, setSectors] = useState<string[]>([]);
  const [currency, setCurrency] = useState<string[]>([]);
  const [currencySelect, setCurrencySelect] = useState(opportunity?.currency);
  const [stepSelect, setStepSelect] = useState(opportunity?.step);
  const [
    currencySelectBeneficiaryWallets,
    setCurrencySelectBeneficiaryWallets,
  ] = useState(opportunity?.beneficiaryWallets?.wallets[0]?.currency);
  const [opportunities, setOpportunities] = useState<string[]>([]);
  const [stage, setStage] = useState<string[]>([]);
  const [steps, setSteps] = useState<string[]>([]);
  const [sectorSelect, setSectorSelect] = useState(opportunity?.sector);
  const [opportunitiesSelect, setOpportunitiesSelect] = useState<
    string | undefined
  >(opportunity?.assetClass);
  const [stageSelect, setStageSelect] = useState(opportunity?.stage);
  const [templates, setTemplates] = useState<string[]>([]);
  const [currentIssueDate, setCurrentIssueDate] = useState("");
  const [templateSelect, setTemplateselect] = useState(
    opportunity?.smartContract?.template
  );
  const [indexDetails, setIndexDetails] = useState<number | undefined>(
    undefined
  );
  const [indexCms, setIndexCms] = useState<number | undefined>(undefined);
  const [smartContractToggle, setSmartContractToggle] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");

  const defaultValues: FormValues = {
    name: opportunity?.name || "",
    currency: currencySelect || "",
    assetClass: opportunitiesSelect || "",
    minRequested: opportunity?.minRequested || "",
    maxRequested: opportunity?.maxRequested || "",
    horizon: opportunity?.horizon || "",
    stage: stageSelect || "",
    sector: sectorSelect || "",
    tokenPrice: opportunity?.tokenPrice || "",
    valuation: opportunity?.valuation || "",
    shortDescription: opportunity?.shortDescription || "",
    longDescription: opportunity?.longDescription || "",
    startDate: opportunity?.startDate
      ? format(new Date(opportunity.startDate), "yyyy-MM-dd")
      : "",
    endDate: opportunity?.endDate
      ? format(new Date(opportunity.endDate), "yyyy-MM-dd")
      : "",
    noToken: opportunity?.noToken || 0,
    bigImageId: opportunity?.bigImageId || 0,
    logoImageId: opportunity?.logoImageId || 0,
    details: opportunity?.details || [],
    keyFacts: opportunity?.keyFacts || [],
    beneficiaryWalletsWalletId:
      opportunity?.beneficiaryWallets?.wallets[0]?.walletId || "",
    beneficiaryWalletsCurrency: currencySelectBeneficiaryWallets || "",
    smartContractName: opportunity?.smartContract?.name || "",
    smartContractTemplate: templateSelect || "",
    smartContractAmount: opportunity?.smartContract?.amount || "",
    smartContractDescription: opportunity?.smartContract?.description || "",
    smartContractPrecision: opportunity?.smartContract?.precision || 0,
    smartContractAddress: opportunity?.smartContract?.address || "",
    smartContractNetwork: opportunity?.smartContract?.network || "",
    step: stepSelect || "",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = (values: FormValues) => {
    if (mode === "create") {
      return onFormSubmit({
        ...values,
      });
    }
    if (mode === "update") {
      return onFormSubmit({
        ...values,
        noToken: opportunity?.noToken || null,
        bigImageId: opportunity?.bigImageId || null,
        logoImageId: opportunity?.logoImageId || null,
        details: opportunity?.details || null,
        keyFacts: opportunity?.keyFacts || null,
      });
    }
  };

  const onSubmitJson = () => {
    return onFormSubmit({
      ...opportunity,
    });
  };

  const fetchReferenceDataValues = async () => {
    try {
      const res = await getInvestementReferenceData();
      setSteps(res?.steps);
      setSectors(res?.sectors);
      setStage(res?.equityStages);
      setOpportunities(res?.opportunities);
      setCurrency(res?.currencies);
      setTemplates(res?.templates);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (mode === "update") {
      resetValues();
    }
    fetchReferenceDataValues();
  }, [dispatch, opportunity]);

  const resetValues = () => {
    let isMounted = true;
    if (isMounted && opportunity) {
      reset({
        name: opportunity?.name,
        currency: opportunity?.currency,
        assetClass: opportunity?.assetClass,
        minRequested: opportunity?.minRequested,
        maxRequested: opportunity?.maxRequested,
        horizon: opportunity?.horizon,
        stage: opportunity?.stage,
        sector: opportunity?.sector,
        tokenPrice: opportunity?.tokenPrice,
        valuation: opportunity?.valuation,
        shortDescription: opportunity?.shortDescription,
        longDescription: opportunity?.longDescription,
        noToken: opportunity?.noToken,
        bigImageId: opportunity?.bigImageId,
        logoImageId: opportunity?.logoImageId,
        details: opportunity?.details,
        keyFacts: opportunity?.keyFacts,
        beneficiaryWalletsWalletId:
          opportunity?.beneficiaryWallets?.wallets[0]?.walletId,
        beneficiaryWalletsCurrency:
          opportunity?.beneficiaryWallets?.wallets[0]?.currency,
        smartContractName: opportunity?.smartContract?.name,
        smartContractTemplate: opportunity?.smartContract?.template,
        smartContractAmount: opportunity?.smartContract?.amount,
        smartContractDescription: opportunity?.smartContract?.description,
        smartContractPrecision: opportunity?.smartContract?.precision,
        step: opportunity?.step,
      } as FormValues);
    }

    return (): void => {
      isMounted = false;
    };
  };

  const onImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (opportunityId && e.target?.files?.length === 1) {
      try {
        await updateImage({
          opportunityId,
          request: { image: e.target.files[0] },
        });
        handleSuccessfulMessage(t("details__label__image_uploaded"));
        history.push(`/opportunities/${opportunityId}`);
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  const onLogoChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (opportunityId && e.target?.files?.length === 1) {
      try {
        await updateLogo({
          opportunityId,
          request: { image: e.target.files[0] },
        });
        handleSuccessfulMessage(t("details__label__logo_uploaded"));
        history.push(`/opportunities/${opportunityId}`);
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  const onMediumImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (opportunityId && e.target?.files?.length === 1) {
      try {
        await updateMediImage({
          opportunityId,
          request: { image: e.target.files[0] },
        });
        handleSuccessfulMessage(t("details__label__logo_uploaded"));
        history.push(`/opportunities/${opportunityId}`);
      } catch (err: any) {
        return handleFailure(err);
      }
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={1}
            direction="row"
            className={classes.gridContainerInfo}
          >
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t("opportunity__section_title__genaral_info")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="name"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__opportunity_name__required"
                  ) as string,
                }}
                label={t("details__label__opportunityName")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="currency"
                label={t("details__label__currency")}
                rules={{
                  required: t("details__label__currency__required") as string,
                }}
                defaultValues={defaultValues}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCurrencySelect(e.target.value);
                }}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allCurrency")}</em>
                </MenuItem>
                {currency?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="assetClass"
                label={t("details__label__assetClass")}
                rules={{
                  required: t(
                    "details__label__opportunity_name__required"
                  ) as string,
                }}
                defaultValues={defaultValues}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setOpportunitiesSelect(e.target.value);
                }}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allAssetClasses")}</em>
                </MenuItem>
                {opportunities?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="step"
                label={t("details__label__step")}
                rules={{
                  required: t(
                    "details__label__opportunity_step__required"
                  ) as string,
                }}
                defaultValues={defaultValues}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setStepSelect(e.target.value);
                }}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allSteps")}</em>
                </MenuItem>
                {steps?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="minRequested"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__min_requested__required"
                  ) as string,
                }}
                label={t("details__label__minRequested")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="maxRequested"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__max_requested__required"
                  ) as string,
                }}
                label={t("details__label__maxRequested")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="horizon"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t("details__label__horizon__required") as string,
                }}
                label={t("details__label__horizon")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="stage"
                label={t("details__label__stage")}
                rules={undefined}
                defaultValues={defaultValues}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setStageSelect(e.target.value);
                }}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allStages")}</em>
                </MenuItem>
                {stage?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {t(`values__stage__${value}`)}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="sector"
                label={t("details__label__sector")}
                rules={{
                  required: t("details__label__sector__required") as string,
                }}
                defaultValues={defaultValues}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSectorSelect(e.target.value);
                }}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allAssetClasses")}</em>
                </MenuItem>
                {sectors?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="tokenPrice"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__token_price__required"
                  ) as string,
                }}
                label={t("details__label__tokenPrice")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="noToken"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t("details__label__no_token__required") as string,
                }}
                label={t("details__label__noToken")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="valuation"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t("details__label__valuation__required") as string,
                }}
                label={t("details__label__valuation")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextareaField
                name="shortDescription"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__short_description__required"
                  ) as string,
                }}
                label={t("details__label__shortDescription")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={8}>
              <QuidTextareaField
                name="longDescription"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t(
                    "details__label__long_description__required"
                  ) as string,
                }}
                label={t("details__label__longDescription")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="startDate"
                textFieldProps={{
                  fullWidth: true,
                  type: "date",
                  InputLabelProps: { shrink: true },
                }}
                rules={{
                  required: t("details__label__start_date__required") as string,
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCurrentIssueDate(e?.target?.value);
                }}
                label={t("details__label__start_date")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="endDate"
                inputProps={{
                  min: currentIssueDate,
                }}
                textFieldProps={{
                  fullWidth: true,
                  type: "date",
                  InputLabelProps: { shrink: true },
                }}
                rules={{
                  required: t("details__label__end_date__required") as string,
                }}
                label={t("details__label__end_date")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t("opportunity__section_title__beneficiary_wallets")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="beneficiaryWalletsWalletId"
                textFieldProps={{
                  fullWidth: true,
                }}
                rules={{
                  required: t("details__label__wallet_id__required") as string,
                }}
                label={t("details__label__wallet_id")}
                defaultValues={defaultValues}
              />
            </Grid>
            <Grid item xs={4}>
              <QuidTextField
                name="beneficiaryWalletsCurrency"
                label={t("details__label__currency")}
                rules={{
                  required: t("details__label__currency__required") as string,
                }}
                defaultValues={defaultValues}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCurrencySelectBeneficiaryWallets(e.target.value);
                }}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              >
                <MenuItem value="">
                  <em>{t("values__allCurrency")}</em>
                </MenuItem>
                {currency?.map((value, index) => (
                  <MenuItem key={`${value}-${index}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </QuidTextField>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.sectionTitle}>
                {t("opportunity__section_title__smart_contract")}
              </Typography>
              {defaultValues.smartContractNetwork === "" ? (
                <>
                  <ToggleButton
                    onClick={() => setSmartContractToggle(!smartContractToggle)}
                  />
                  {smartContractToggle && (
                    <Grid container spacing={1} direction="row">
                      <Grid item xs={4}>
                        <QuidTextField
                          name="smartContractName"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={
                            smartContractToggle && {
                              required: t(
                                "details__label__opportunity_smart_contract_name__required"
                              ) as string,
                            }
                          }
                          label={t(
                            "details__label__opportunity_smart_contract_name"
                          )}
                          defaultValues={defaultValues}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <QuidTextField
                          name="smartContractTemplate"
                          rules={
                            smartContractToggle && {
                              required: t(
                                "details__label__opportunity_smart_contract_template__required"
                              ) as string,
                            }
                          }
                          label={t(
                            "details__label__opportunity_smart_contract_template"
                          )}
                          defaultValues={defaultValues}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setTemplateselect(e.target.value);
                          }}
                          textFieldProps={{
                            select: true,
                            fullWidth: true,
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                        >
                          <MenuItem value="">
                            <em>{t("values__allStages")}</em>
                          </MenuItem>
                          {templates?.map((value, index) => (
                            <MenuItem key={`${value}-${index}`} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </QuidTextField>
                      </Grid>
                      <Grid item xs={4}>
                        <QuidTextField
                          name="smartContractAmount"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={
                            smartContractToggle && {
                              required: t(
                                "details__label__opportunity_smart_contract_amount__required"
                              ) as string,
                            }
                          }
                          label={t(
                            "details__label__opportunity_smart_contract_amount"
                          )}
                          defaultValues={defaultValues}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <QuidTextField
                          name="smartContractPrecision"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={
                            smartContractToggle && {
                              required: t(
                                "details__label__opportunity_smart_contract_precision__required"
                              ) as string,
                            }
                          }
                          label={t(
                            "details__label__opportunity_smart_contract_precision"
                          )}
                          defaultValues={defaultValues}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <QuidTextField
                          name="smartContractDescription"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={
                            smartContractToggle && {
                              required: t(
                                "details__label__opportunity_smart_contract_description__required"
                              ) as string,
                            }
                          }
                          label={t(
                            "details__label__opportunity_smart_contract_description"
                          )}
                          defaultValues={defaultValues}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  <Grid item xs={4}>
                    <QuidTextField
                      name="smartContractAddress"
                      textFieldProps={{
                        fullWidth: true,
                      }}
                      rules={undefined}
                      label={t(
                        "details__label__opportunity_smart_contract_address"
                      )}
                      disabled
                      defaultValues={defaultValues}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <QuidTextField
                      name="smartContractNetwork"
                      textFieldProps={{
                        fullWidth: true,
                      }}
                      rules={undefined}
                      label={t(
                        "details__label__opportunity_smart_contract_network"
                      )}
                      disabled
                      defaultValues={defaultValues}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {mode === "create" && (
              <Grid item xs={12}>
                <Button
                  className={classes.updateBtn}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  type="submit"
                >
                  {t("opportunity__create_page__create_button")}
                </Button>
              </Grid>
            )}
            {mode === "update" && (
              <Grid item xs={12}>
                <Button
                  className={classes.updateBtn}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  type="submit"
                >
                  {t("opportunity__update_button")}
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
      {mode === "update" && (
        <>
          <div className={classes.containerJson}>
            <QuidTitle>{t("details__label__title__opportinity_cms")}</QuidTitle>
            <div className={classes.gridContainerJsonRow}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t("details__label__json_details")}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {opportunity?.details?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          if (indexDetails) {
                            setIndexDetails(undefined);
                          } else {
                            setIndexDetails(index);
                          }
                          setIndexCms(undefined);
                        }}
                        className={classes.sectionTitleBox}
                      >
                        <Typography
                          variant="h6"
                          className={classes.sectionTitle}
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body1">
                          {item.description}
                        </Typography>
                      </div>
                    );
                  })}
                  <Button
                    variant="contained"
                    color="primary"
                    className="actions actions-reject"
                    onClick={() => {
                      if (opportunity) {
                        const newDetails = opportunity?.details?.length
                          ? [
                              { title: "todo", description: "todo" },
                              ...opportunity?.details,
                            ]
                          : [{ title: "todo", description: "todo" }];

                        opportunity.details = newDetails;
                        onSubmitJson();
                      }
                    }}
                  >
                    {t("add")}
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  {indexDetails !== undefined && (
                    <FormProvider {...methods}>
                      <form onSubmit={handleSubmit(onSubmitJson)}>
                        <QuidTextField
                          name="title"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={undefined}
                          label={t("title")}
                          defaultValues={{
                            title: opportunity?.details?.[indexDetails]?.title,
                          }}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <QuidTextareaField
                          name="description"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={{
                            required: t(
                              "details__label__short_description__required"
                            ) as string,
                          }}
                          label={t("description")}
                          defaultValues={{
                            description:
                              opportunity?.details?.[indexDetails]?.description,
                          }}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <div className={classes.buttonsCn}>
                          <Button
                            variant="contained"
                            color="primary"
                            className="actions actions-reject"
                            onClick={() => {
                              if (opportunity) {
                                const newdetails = opportunity?.details || [
                                  { title: "", description: "" },
                                ];
                                newdetails[indexDetails] = {
                                  title,
                                  description,
                                };
                                opportunity.details = newdetails || [
                                  { title: "", description: "" },
                                ];
                                onSubmitJson();
                              }
                            }}
                          >
                            {t("modify")}
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="actions actions-reject"
                            onClick={() => {
                              if (opportunity) {
                                const newDetails = opportunity?.details;
                                newDetails?.splice(indexDetails, 1);
                                opportunity.details = newDetails;
                                setIndexDetails(undefined);
                                onSubmitJson();
                              }
                            }}
                          >
                            {t("delete")}
                          </Button>
                        </div>
                      </form>
                    </FormProvider>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className={classes.gridContainerJsonRow}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t("details__label__json_key_facts")}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {opportunity?.keyFacts?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setIndexDetails(undefined);
                          setIndexCms(index);
                        }}
                        className={classes.sectionTitleBox}
                      >
                        <Typography
                          variant="h6"
                          className={classes.sectionTitle}
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body1">
                          {item.description}
                        </Typography>
                      </div>
                    );
                  })}
                  <Button
                    variant="contained"
                    color="primary"
                    className="actions actions-reject"
                    onClick={() => {
                      if (opportunity) {
                        const newkeyFacts = opportunity?.keyFacts?.length
                          ? [
                              { title: "todo", description: "todo" },
                              ...opportunity?.keyFacts,
                            ]
                          : [{ title: "todo", description: "todo" }];

                        opportunity.keyFacts = newkeyFacts;
                        onSubmitJson();
                      }
                    }}
                  >
                    {t("add")}
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  {indexCms !== undefined && (
                    <FormProvider {...methods}>
                      <form onSubmit={handleSubmit(onSubmitJson)}>
                        <QuidTextField
                          name="title"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={undefined}
                          label={t("title")}
                          defaultValues={{
                            title: opportunity?.keyFacts?.[indexCms]?.title,
                          }}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <QuidTextareaField
                          name="description"
                          textFieldProps={{
                            fullWidth: true,
                          }}
                          rules={{
                            required: t(
                              "details__label__short_description__required"
                            ) as string,
                          }}
                          label={t("description")}
                          defaultValues={{
                            description:
                              opportunity?.keyFacts?.[indexCms]?.description,
                          }}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <div className={classes.buttonsCn}>
                          <Button
                            variant="contained"
                            color="primary"
                            className="actions actions-reject"
                            onClick={() => {
                              if (opportunity) {
                                const newkeyFacts = opportunity?.keyFacts || [
                                  { title: "", description: "" },
                                ];
                                newkeyFacts[indexCms] = {
                                  title,
                                  description,
                                };
                                opportunity.keyFacts = newkeyFacts || [
                                  { title: "", description: "" },
                                ];
                                onSubmitJson();
                              }
                            }}
                          >
                            {t("modify")}
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            className="actions actions-reject"
                            onClick={() => {
                              if (opportunity) {
                                const newkeyFacts = opportunity?.keyFacts;
                                newkeyFacts?.splice(indexCms, 1);
                                opportunity.keyFacts = newkeyFacts;
                                setIndexCms(undefined);
                                onSubmitJson();
                              }
                            }}
                          >
                            {t("delete")}
                          </Button>
                        </div>
                      </form>
                    </FormProvider>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={classes.containerJson}>
            <QuidTitle>
              {t("details__label__title__opportinity_images")}
            </QuidTitle>
            <Grid
              container
              spacing={2}
              direction="row"
              className={classes.gridContainerImages}
            >
              <Grid item xs={4}>
                <OpportunityImage
                  imageId={opportunity?.mediumImageId as number}
                  type={"image"}
                  className={classes.alignImage}
                />
                <QuidUploadField
                  inputId="uploadMediumImage"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onMediumImageChange(e)
                  }
                  label={t("details__label__upload_medium_image")}
                />
              </Grid>
              <Grid item xs={4}>
                <OpportunityImage
                  imageId={opportunity?.bigImageId as number}
                  type={"image"}
                  className={classes.alignImage}
                />
                <QuidUploadField
                  inputId="uploadImage"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onImageChange(e)
                  }
                  label={t("details__label__upload_image")}
                />
              </Grid>
              <Grid item xs={4}>
                <OpportunityImage
                  imageId={opportunity?.logoImageId as number}
                  type={"image"}
                  className={classes.alignImage}
                />
                <QuidUploadField
                  inputId="uploadLogo"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onLogoChange(e)
                  }
                  label={t("details__label__upload_logo")}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default OpportunitiesCreateOrEdit;
