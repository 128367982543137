import React, { useEffect, useState } from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ColDef } from "@material-ui/data-grid";
import { useRouteMatch } from "react-router";
import { useSelector } from "react-redux";
import { getPartiesCountries } from "store/reducers/app.reducer";
import ApproveRejectBox from "../../atoms/ApproveRejectBox";
import { formatDate } from "utils";
import { acceptTask, rejectTask } from "api/tasks";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import fileDownload from "js-file-download";
import VerificationTemplate from "templates/VerificationTemplate";
import CreateOrUpdateCompanyDocumentWithDetails from "../../molecules/verifications/CreateOrUpdateCompanyDocumentWithDetails";
import jwt_decode from "jwt-decode";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { GenericObject } from "entities/tasks/Identity";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import { apiClient } from "@portit/core/utils/api/apiClient";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";

const useStyles = makeStyles(() => ({
  // TODO
  // root: {
  //   marginTop: 16,
  //   marginLeft: 16,
  //   marginRight: 16,
  // },
  bottomBoxContainer: {
    marginTop: 20,
  },
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 50px 0 50px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    marginBottom: 50,
  },
}));

const checkCompanyDetailsCheck = {
  ALL_INFORMATIONS_ARE_CORRECT: false,
};

const UploadCompanyFormationConfirmationComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
}> = ({ columns, isHistory, task, refetch }) => {
  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();

  const companyDetails = task?.variables?.companyDetailsResponse;
  const taxRegistrationGst = task?.variables?.taxRegistrationGst;
  const taxRegistrationIrd = task?.variables?.taxRegistrationIrd;
  const companyDirectors = task?.variables?.companyDirectors;
  const companyDocumentCompanyFormation = task?.documents?.find(
    (d) => d.type === "COMPANY-FORMATION" && d.subType === "INCORPORATION"
  );
  const [documents, setDocuments] = useState(
    task?.documents?.filter(
      (d) => d.type === "COMPANY-FORMATION" && d.subType === "INCORPORATION"
    )
  );
  // if there are no documents, set documents to a fake one so the user cant approve the task
  if (documents?.length === 0)
    setDocuments([
      {
        id: 0,
        partyId: 0,
        uploaderId: 0,
        profileId: 0,
        type: "",
        subType: "",
        uploadDate: "",
        originalName: "",
        country: "",
        name: "",
        additionalInfo: "",
        fourStopDocumentId: 0,
        fourStopReference: "",
        fourStopResult: "",
        fourStopCompleted: false,
      },
    ]);

  useEffect(() => {
    setDocuments(
      task?.documents?.filter(
        (d) => d.type === "COMPANY-FORMATION" && d.subType === "INCORPORATION"
      )
    );
  }, [task?.documents]);

  const companyShareholdersOrganization = task?.variables?.companyShareholders.filter(
    (shareholderRow: GenericObject) => {
      return shareholderRow?.shareholderType === "Organisation";
    }
  );
  const companyShareholdersPerson = task?.variables?.companyShareholders.filter(
    (shareholderRow: GenericObject) => {
      return shareholderRow?.shareholderType === "Person";
    }
  );
  const companyCommunicationAddress =
    task?.variables?.companyCommunicationAddress;
  const companyRegisteredAddress = task?.variables?.companyRegisteredAddress;
  const taxCommunicationAddress = task?.variables?.taxCommunicationAddress;
  const companyServiceAddress = task?.variables?.companyServiceAddress;
  const taxPremiseAddress = task?.variables?.taxPremiseAddress;

  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  if (!task) {
    return null;
  }

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  const corporateIncorporationResultToPrint = {
    nameReservationAccepted: task?.variables?.nameReservationAccepted
      ? "Yes"
      : "No",
    executionResult: task?.variables?.executionResult ? "Yes" : "No",
  };

  const corporateDetailsToPrint = {
    companyId: companyDetails?.companyId,
    companyName: companyDetails?.companyName,
    companyUuid: companyDetails?.companyUuid,
    email: companyDetails?.email,
    annual__return__filing__month:
      companyDetails?.annual__return__filing__month,
  };

  const taxRegistrationGstToPrint = {
    taxRegistrationGstId: taxRegistrationGst?.taxRegistrationGstId,
    accountingMethod: taxRegistrationGst?.accountingMethod,
    filingFrequency: taxRegistrationGst?.filingFrequency,
    questionsDifficulty: taxRegistrationGst?.questionsDifficulty,
    contactName: `${taxRegistrationGst?.contactName?.lastName}
     ${taxRegistrationGst?.contactName?.middleNames ?? ""}
     ${taxRegistrationGst?.contactName?.firstName}`,
    contactPhone: `${taxRegistrationGst?.contactPhone?.countryCode}
     ${taxRegistrationGst?.contactPhone?.areaCode}
     ${taxRegistrationGst?.contactPhone?.phoneNumber}`,
    refundBankAccount: `${taxRegistrationGst?.refundBankAccount?.account}
     ${taxRegistrationGst?.refundBankAccount?.branch}
     ${taxRegistrationGst?.refundBankAccount?.bank}
     ${taxRegistrationGst?.refundBankAccount?.suffix}`,
    exemptGst: taxRegistrationGst?.exemptGs0t ? "Yes" : "No",
    exportingGoods: taxRegistrationGst?.exportingGoods ? "Yes" : "No",
    importingGoods: taxRegistrationGst?.importingGoods ? "Yes" : "No",
    workedWithGst: taxRegistrationGst?.workedWithGst ? "Yes" : "No",
    exceeds60K: taxRegistrationGst?.exceeds60K ? "Yes" : "No",
  };

  const taxRegistrationIrdToPrint = {
    taxRegistrationIrdId: taxRegistrationIrd?.taxRegistrationIrdId,
    bicCode: taxRegistrationIrd?.bicCode,
    contactName: `${taxRegistrationIrd?.companyContactTaxRegistration?.lastName}
     ${taxRegistrationIrd?.companyContactTaxRegistration?.middleNames ?? ""}
     ${taxRegistrationIrd?.companyContactTaxRegistration?.firstName}`,
    contactPhone: `${taxRegistrationIrd?.companyContactTaxRegistration?.contactPhone?.countryCode}
      ${taxRegistrationIrd?.companyContactTaxRegistration?.contactPhone?.areaCode}
      ${taxRegistrationIrd?.companyContactTaxRegistration?.contactPhone?.phoneNumber}`,
    companyContactEmail: taxRegistrationIrd?.companyContact?.email,
    companyContactPhone: `${taxRegistrationIrd?.companyContact?.phoneContact?.countryCode}
      ${taxRegistrationIrd?.companyContact?.phoneContact?.areaCode}
      ${taxRegistrationIrd?.companyContact?.phoneContact?.phoneNumber}`,
    overseasShareholding: taxRegistrationIrd?.overseasShareholding
      ? "Yes"
      : "No",
    taxNonActive: taxRegistrationIrd?.taxNonActive ? "Yes" : "No",
    offeringFringeBenefits: taxRegistrationIrd?.offeringFringeBenefits
      ? "Yes"
      : "No",
  };

  const companyCommunicationAddressToPrint = {
    addressLine1: companyCommunicationAddress?.addressLine1,
    addressLine2: companyCommunicationAddress?.addressLine2,
    city: companyCommunicationAddress?.city,
    postalCode: companyCommunicationAddress?.postalCode,
    state: companyCommunicationAddress?.state,
    country: getCountry(companyCommunicationAddress?.country),
  };

  const companyRegisteredAddressToPrint = {
    addressLine1: companyRegisteredAddress?.addressLine1,
    addressLine2: companyRegisteredAddress?.addressLine2,
    city: companyRegisteredAddress?.city,
    postalCode: companyRegisteredAddress?.postalCode,
    state: companyRegisteredAddress?.state,
    country: getCountry(companyRegisteredAddress?.country),
  };

  const taxCommunicationAddressToPrint = {
    addressLine1: taxCommunicationAddress?.addressLine1,
    addressLine2: taxCommunicationAddress?.addressLine2,
    city: taxCommunicationAddress?.city,
    postalCode: taxCommunicationAddress?.postalCode,
    state: taxCommunicationAddress?.state,
    country: getCountry(taxCommunicationAddress?.country),
  };

  const companyServiceAddressToPrint = {
    addressLine1: companyServiceAddress?.addressLine1,
    addressLine2: companyServiceAddress?.addressLine2,
    city: companyServiceAddress?.city,
    postalCode: companyServiceAddress?.postalCode,
    state: companyServiceAddress?.state,
    country: getCountry(companyServiceAddress?.country),
  };

  const taxPremiseAddressToPrint = {
    addressLine1: taxPremiseAddress?.addressLine1,
    addressLine2: taxPremiseAddress?.addressLine2,
    city: taxPremiseAddress?.city,
    postalCode: taxPremiseAddress?.postalCode,
    state: taxPremiseAddress?.state,
    country: getCountry(taxPremiseAddress?.country),
  };

  const companyDirectorsToPrint = (companyDirector: GenericObject) => ({
    directorId: companyDirector?.directorId,
    firstName: companyDirector?.personInRole?.name?.firstName,
    lastName: companyDirector?.personInRole?.name?.lastName,
    dateOfBirth: formatDate(
      companyDirector?.personInRole?.birthInfo?.dateOfBirth
    ),
    countryOfBirth: getCountry(
      companyDirector?.personInRole?.birthInfo?.countryOfBirth
    ),
  });

  const companyShareholdersPersonToPrint = (
    companyShareholder: GenericObject
  ) => ({
    shareholderId: companyShareholder?.shareholderId,
    firstName: companyShareholder?.personShareholder?.name?.firstName,
    lastName: companyShareholder?.personShareholder?.name?.lastName,
    dateOfBirth: formatDate(
      companyShareholder?.personShareholder?.birthInfo?.dateOfBirth
    ),
    countryOfBirth: getCountry(
      companyShareholder?.personShareholder?.birthInfo?.countryOfBirth
    ),
    city: companyShareholder?.personShareholder?.birthInfo?.countryOfBirth,
    type: companyShareholder?.shareholderType,
    numberOfShares: companyShareholder?.numberOfShares,
    contactPhone: `${companyShareholder?.contacts?.phoneContacts?.countryCode}
     ${companyShareholder?.contacts?.phoneContacts?.areaCode}
     ${companyShareholder?.contacts?.phoneContacts?.phoneNumber}`,
    contactEmail: companyShareholder?.contacts?.emailAddresses?.emailAddress,
  });

  const companyShareholdersOrganizationToPrint = (
    companyShareholder: GenericObject
  ) => ({
    shareholderId: companyShareholder?.shareholderId,
    registrationNumber:
      companyShareholder?.organisationShareholder?.registrationNumber,
    name: companyShareholder?.organisationShareholder?.name,
    entityType: companyShareholder?.organisationShareholder?.entityType,
    nzbn: companyShareholder?.organisationShareholder?.nzbn,
    countryOfOrigin: getCountry(
      companyShareholder?.organisationShareholder?.countryOfOrigin
    ),
    type: companyShareholder?.shareholderType,
    numberOfShares: companyShareholder?.numberOfShares,
    contactPhone: `${companyShareholder?.contacts?.phoneContacts?.countryCode}
     ${companyShareholder?.contacts?.phoneContacts?.areaCode}
     ${companyShareholder?.contacts?.phoneContacts?.phoneNumber}`,
    contactEmail: companyShareholder?.contacts?.emailAddresses?.emailAddress,
  });

  const downloadDoc = async (docId: number) => {
    try {
      const res: any = await apiClient.get(
        `/companies/v1/companies/documents/${docId}`,
        "companyFormation",
        {},
        {
          responseType: "arraybuffer",
          transformResponse: (data: any, headers?: any): any => {
            return {
              data: {
                buffer: data,
                contentType: headers["content-type"],
              },
            } as FilePayload;
          },
        }
      );
      fileDownload(
        res.data.buffer,
        `${docId.toString()}.pdf`,
        res.data.contentType
      );
    } catch (e) {
      // TODO!
    }
  };
  return (
    <VerificationTemplate
      refetch={refetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        <div className={classes.detailsWrapperQuarter}>
          <DetailsBlock
            toPrint={corporateDetailsToPrint}
            label="corporate__details__title"
          />
          <DetailsBlock
            toPrint={corporateIncorporationResultToPrint}
            label="corporate__incorporation__result__title"
          />
        </div>
        {taxRegistrationGst && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={taxRegistrationGstToPrint}
              label="tax__registration__gst__title"
            />
          </div>
        )}
        {taxRegistrationIrd && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={taxRegistrationIrdToPrint}
              label="tax__registration__gst__title"
            />
          </div>
        )}
        <div className={classes.detailsWrapperQuarter}>
          <Typography variant="h6" className="section-title">
            Company Formation Document
          </Typography>
          <CreateOrUpdateCompanyDocumentWithDetails
            refetch={refetch}
            taskId={taskId}
            taskKey={taskKey}
            companyId={companyDetails?.companyId}
            doc={companyDocumentCompanyFormation}
            canReplace={isHistory === undefined && isAssignedToUser}
            isHistory={isHistory}
          />
        </div>
      </div>
      <div className={classes.topContainer}>
        {companyCommunicationAddress && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={companyCommunicationAddressToPrint}
              label="company__communication__address__title"
            />
          </div>
        )}
        {companyRegisteredAddress && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={companyRegisteredAddressToPrint}
              label="company__registration__address__title"
            />
          </div>
        )}
        {companyServiceAddress && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={companyServiceAddressToPrint}
              label="company__service__address__title"
            />
          </div>
        )}
      </div>
      <div className={classes.topContainer}>
        {taxCommunicationAddress && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={taxCommunicationAddressToPrint}
              label="tax__communication__address__title"
            />
          </div>
        )}
        {taxPremiseAddress && (
          <div className={classes.detailsWrapperQuarter}>
            <DetailsBlock
              toPrint={taxPremiseAddressToPrint}
              label="tax__premise__address__title"
            />
          </div>
        )}
      </div>
      <div className={classes.topContainer}>
        {companyDirectors?.map((director: GenericObject, index: number) => (
          <div
            className={classes.detailsWrapperQuarter}
            key={`cmp-director-${index}`}
          >
            <DetailsBlock
              toPrint={companyDirectorsToPrint(director)}
              label="company__director__title"
              key={`cmp-director-${index}`}
            />
            <Link
              href="javascript:void(0)"
              onClick={() => downloadDoc(director.documentId)}
            >
              <Typography>Download document</Typography>
            </Link>
          </div>
        ))}
        {companyShareholdersPerson?.map((shareholder: any, index: number) => (
          <div
            className={classes.detailsWrapperQuarter}
            key={`cmp-shareholder-${index}`}
          >
            <DetailsBlock
              toPrint={companyShareholdersPersonToPrint(shareholder)}
              label="company__shareholder__title"
            />
            <Link
              href="javascript:void(0)"
              onClick={() => downloadDoc(shareholder.documentId)}
            >
              <Typography>Download document</Typography>
            </Link>
          </div>
        ))}
        {companyShareholdersOrganization?.map(
          (shareholder: any, index: number) => (
            <div
              className={classes.detailsWrapperQuarter}
              key={`cmp-shareholder-${index}`}
            >
              <DetailsBlock
                toPrint={companyShareholdersOrganizationToPrint(shareholder)}
                label="company__shareholder__title"
              />
              <Link
                href="javascript:void(0)"
                onClick={() => downloadDoc(shareholder.documentId)}
              >
                <Typography>Download document</Typography>
              </Link>
            </div>
          )
        )}
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container className={classes.bottomBoxContainer}>
          <ApproveRejectBox
            docs={documents}
            checks={checkCompanyDetailsCheck}
            task={task}
            accept={() => acceptTask({ taskId, taskKey })}
            reject={() => rejectTask({ taskId, taskKey })}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default UploadCompanyFormationConfirmationComp;
