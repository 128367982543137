import React from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import { postCryptoTransfer } from "@portit/core/api/Crypto";
import QuidTextareaField from "components/atoms/QuidTextareaField";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  form: {
    maxWidth: "100%",
  },
  flex1: {
    flex: 1,
  },
  mt56: {
    marginTop: 56,
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  sectionTitle: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    marginBottom: "5px",
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 56,
    gap: "1rem",
  },
  "& .actions": {
    minHeight: 50,
    margin: "0 10px",

    "&-reject": {
      backgroundColor: "#FF6F0F",
    },
  },
}));

interface FormValues {
  amount: number;
  projectName: string;
  walletAddress: string;
  destinationAddress: string;
  smartContractAddress: string;
}

const CryptoPaymentTransfer: React.FC = () => {
  const { t } = useTranslation("crypto");
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultValues = {
    amount: "",
    projectName: "",
    walletAddress: "",
    destinationAddress: "",
    smartContractAddress: "",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  const onResetForm = () => {
    reset(defaultValues);
  };

  const onFormSubmit = async (values: FormValues) => {
    try {
      await postCryptoTransfer({
        owner: null,
        network: null,
        tokenId: null,
        uintAmount: null,
        metadataUri: null,
        amount: values.amount,
        projectName: values.projectName,
        walletAddress: values.walletAddress,
        destinationAddress: values.destinationAddress,
        smartContractAddress: values.smartContractAddress,
      });
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("crypto__transfer__success__message"),
          type: "success",
        })
      );
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  return (
    <MainTemplate>
      <QuidTitle>{t("crypto__transfer__page__title")}</QuidTitle>
      <div className={classes.container}>
        <FormProvider {...methods}>
          <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container direction={"column"} spacing={2}>
              <Grid container direction={"row"} spacing={1}>
                <Grid item xs={6}>
                  <QuidTextField
                    name="projectName"
                    label={t("crypto__projectName")}
                    rules={{
                      required: t("crypto__projectName__required") as string,
                    }}
                    textFieldProps={{
                      fullWidth: true,
                    }}
                    defaultValues={defaultValues}
                  />
                </Grid>
                <Grid item xs={6}>
                  <QuidTextField
                    name="amount"
                    label={t("crypto__amount")}
                    rules={{
                      required: t("crypto__amount__required") as string,
                    }}
                    textFieldProps={{
                      fullWidth: true,
                    }}
                    defaultValues={defaultValues}
                  />
                </Grid>
                <Grid container direction={"column"} spacing={1}>
                  <Grid item>
                    <QuidTextareaField
                      name="destinationAddress"
                      label={t("crypto__destinationAddress")}
                      rules={{
                        required: t(
                          "crypto__destinationAddress__required"
                        ) as string,
                      }}
                      textFieldProps={{
                        fullWidth: true,
                      }}
                      defaultValues={defaultValues}
                    />
                  </Grid>
                  <Grid item>
                    <QuidTextareaField
                      name="smartContractAddress"
                      label={t("crypto__smartContractAddress")}
                      rules={{
                        required: t(
                          "crypto__smartContractAddress__required"
                        ) as string,
                      }}
                      textFieldProps={{
                        fullWidth: true,
                      }}
                      defaultValues={defaultValues}
                    />
                  </Grid>
                  <Grid item>
                    <QuidTextareaField
                      name="walletAddress"
                      label={t("crypto__walletAddress")}
                      rules={{
                        required: t(
                          "crypto__walletAddress__required"
                        ) as string,
                      }}
                      textFieldProps={{
                        fullWidth: true,
                      }}
                      defaultValues={defaultValues}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.buttonsWrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onResetForm}
                  className="actions actions-approve"
                >
                  {t("crypto__transfer__action__reset__transfer")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="actions actions-approve"
                >
                  {t("crypto__transfer__action__submit__transfer")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    </MainTemplate>
  );
};

export default CryptoPaymentTransfer;
