import DineroFactory from "dinero.js";
import Dinero from "dinero.js";
import { toCents } from "utils";

export const MoneyFormatter = (
  amount: number,
  currency: DineroFactory.Currency | "USDT" | "AIVAR" | "BTC"
) => {
  try {
    if (amount === null || !currency) return "n/a";
    if (currency === "USDT" || currency === "AIVAR" || currency === "BTC")
      return `${currency} ${amount}`;
    return Dinero({ amount: toCents(amount), currency }).toFormat("$0,0.00");
  } catch (e) {
    return `${currency?.toString()} ${amount?.toPrecision(3).toString()}`;
  }
};
