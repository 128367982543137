import ProductsList from "components/organisms/common/ProductsList";
import { CompanyDetails } from "entities/clients/CompanyEntity";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import { fetchCompanyDetails } from "services/companies";
import { fetchCustomerDetails } from "services/customers";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";

const Products: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const id: number = +match.params.id;
  const from = history?.location?.state as Object;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<CustomerDetails | CompanyDetails>();
  const [isCustomer, setIsCustomer] = useState(false);

  useEffect(() => {
    if (id && Object.values(from)[0] === "customer") {
      void getCustomerDetails(id);
      setIsCustomer(true);
    }
    if (id && Object.values(from)[0] === "company") {
      void getCompanyDetails(id);
      setIsCustomer(false);
    }
  }, [id]);

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setDetails(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setDetails]
  );

  const getCompanyDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const companyDetails = await fetchCompanyDetails({
          companyId: id as number,
        });
        setDetails(companyDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setDetails]
  );

  return (
    <MainTemplate>
      <ProductsList
        partyId={id}
        isCustomer={isCustomer}
        refetch={() => getCustomerDetails(id)}
        contracts={Object.values(details?.products?.contracts ?? [])}
        loading={loading}
      ></ProductsList>
    </MainTemplate>
  );
};

export default Products;
