import { makeStyles, Grid, Button } from "@material-ui/core";
import { ColDef } from "@material-ui/data-grid";
import DetailIcon from "components/atoms/icons/DetailIcon";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import QuidTitle from "components/atoms/QuidTitle";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { formatDate } from "utils";
import { getManagementChangeable } from "api/conversion";
import { FxManagementChangeableItem } from "entities/clients/ConversionEntity";
import ChangeFxModal from "components/organisms/conversion/ChangeFxModal";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  paymentsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flexTextFields: {
    flex: 1,
  },
  flexSpace: {
    flex: 0.1,
  },
  flexCsv: {
    flex: 0.1,
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  detailLink: {
    color: theme.palette.primary.main,
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  statusType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  typeContainer: {
    marginLeft: "19px",
  },
}));

const ConversionsChangeFx: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(["payments", "account"]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<FxManagementChangeableItem>();
  const [
    currentItemInv,
    setCurrentItemInv,
  ] = useState<FxManagementChangeableItem>();
  const [managementChangeable, setManagementChangeable] = useState<
    FxManagementChangeableItem[]
  >([]);

  const fetchManagementChangeable = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getManagementChangeable();
      setManagementChangeable(res?.rates);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchManagementChangeable();
  }, []);

  const handleRowClick = (rowItem: any) => {
    if (rowItem) {
      setModalOpen(true);
      setCurrentItem(rowItem);
      setCurrentItemInv(
        managementChangeable.filter(
          (rate) =>
            rate.clientBuyRate === rowItem.clientSellRate &&
            rate.clientSellRate === rowItem.clientBuyRate
        )[0]
      );
    }
  };

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", width: 75 },
    {
      width: 150,
      type: "date",
      field: "settlementCutOffTime",
      headerName: t("table__header_name__settlementCutOffTime"),
      renderCell: (params) => {
        return (
          <div className={classes.dateType}>
            {formatDate(params.row?.settlementCutOffTime, "dd/MM/yyyy")}
            <small>
              {formatDate(params.row?.settlementCutOffTime, "HH:mm")}
            </small>
          </div>
        );
      },
    },
    {
      field: "currencyPair",
      headerName: t("table__header_name__currencyPair"),
      flex: 1,
    },
    {
      field: "clientBuyCurrency",
      headerName: t("table__header_name__clientBuyCurrency"),
      flex: 1,
    },
    {
      field: "clientSellCurrency",
      headerName: t("table__header_name__clientSellCurrency"),
      flex: 1,
    },
    {
      field: "clientBuyRate",
      headerName: t("table__header_name__clientBuyRate"),
      flex: 1,
    },
    {
      field: "clientSellRate",
      headerName: t("table__header_name__clientSellRate"),
      flex: 1,
    },
    {
      field: "fixedSide",
      headerName: t("table__header_name__fixedSide"),
      flex: 1,
    },
    {
      field: "updateDate",
      headerName: t("table__header_name__updateDate"),
      flex: 1,
    },
    {
      field: "modified",
      width: 100,
      headerName: "Details",
      renderCell: (params) => {
        return (
          <Button onClick={() => handleRowClick(params.row)}>
            <DetailIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <MainTemplate>
      <ChangeFxModal
        open={modalOpen}
        data={currentItem}
        dataInv={currentItemInv}
        setOpen={setModalOpen}
        refetch={fetchManagementChangeable}
      />
      <div className={classes.paymentsContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("conversions__page__title")}</QuidTitle>
          </Grid>
        </Grid>
        <QuidDataGrid
          loading={loading}
          columns={columns}
          rows={managementChangeable}
          rowCount={managementChangeable?.length}
          sortModel={[{ field: "id", sort: "desc" }]}
        />
      </div>
    </MainTemplate>
  );
};

export default ConversionsChangeFx;
