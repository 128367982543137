import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router";
import { ColDef } from "@material-ui/data-grid";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { getPartiesCountries } from "store/reducers/app.reducer";
import AttachmentWithoutPreview from "components/molecules/verifications/AttachmentWithoutPreview";
import VerificationTemplate from "templates/VerificationTemplate";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import QuidTitle from "components/atoms/QuidTitle";
import ApproveRejectKycIndividualBox from "components/atoms/ApproveRejectKyIndividualBox";
import { acceptTask, rejectTask } from "api/tasks";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { Document } from "entities/clients/DocumentEntity";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

export interface IdentityDetails {
  [key: string]: string;
}

export interface FsDetails {
  name: string;
  surname: string;
  nationality: string;
  birthDate: string;
  email: string;
  phone: string;
  gender: string;
}

export interface BusinessResult {
  fourStopCorporateId: number;
  status: string;
  complete: boolean;
}

export interface CustomerResultToPrint {
  fourStopRegistrationId: string;
  fourStopId: number;
}

interface FsDocumentsResults {
  id: string;
  fourStopDoocumentId: number;
  fourStopReference: string;
  fourStopResult: string;
}

export interface FsIndividual {
  [key: string]: string;
}

export interface FourStopResult {
  [key: string]: number | string;
}

export interface GeoCheck {
  [key: string]: number | string;
}
export interface FsIdDetailsToPrint {
  [key: string]: number | string;
}

export interface IdCheck {
  [key: string]: number | string;
}

export interface ScreeningToPrint {
  [key: string]: number | string;
}

const FourStopIndividualVerification: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
}> = ({ columns, isHistory, task, refetch }) => {
  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  const taskVariables = task?.variables;
  const addressDetail = task?.variables?.address;
  const fsIndividual = task?.variables?.fsIndividual;
  const customerResult = task?.variables?.fourStopCustomerResult;
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;
  const proofOfAddress = task?.documents.filter(
    (document: Document) => document.type === "PROOF_OF_ADDRESS"
  );
  const fourStopDataJson = customerResult?.fourStopData
    ? JSON.parse(customerResult?.fourStopData)
    : "";

  const mergeDocs = (
    allDocs: Document[] | undefined,
    fsDocs: FsDocumentsResults[]
  ) => {
    if (allDocs && fsDocs) {
      const array: any = [];
      fsDocs.map((fsDoc: FsDocumentsResults) => {
        allDocs.forEach((allDoc: Document) => {
          if (allDoc.id.toString() === fsDoc.id) {
            array.push({ ...allDoc, ...fsDoc });
          }
        });
      });
      return array;
    }
  };

  const mergedDocsInfo = mergeDocs(
    task?.documents,
    taskVariables?.fourStopDocumentsResults
  );

  const fsIndividualToPrint: FsIndividual = {
    name: fsIndividual?.name || "n/a",
    surname: fsIndividual?.surname || "n/a",
    birthDate: fsIndividual?.birthDate || "n/a",
    nationality: fsIndividual?.nationality || "n/a",
    email: fsIndividual?.email || "n/a",
    phone: fsIndividual?.phone || "n/a",
    gender: fsIndividual?.gender || "n/a",
  };

  const toPrintAddress: IdentityDetails = {
    type: addressDetail?.type || "n/a",
    addressLine1: addressDetail?.addressLine1,
    addressLine2: addressDetail?.addressLine2 || "--",
    city: addressDetail?.city,
    country: getCountry(addressDetail?.country) || "",
    postalCode: addressDetail?.postalCode,
    ip: fsIndividual?.ip || "n/a",
  };

  const toPrintFsResult: FourStopResult = {
    fourStopId: customerResult?.fourStopId || "n/a",
    fourStopRegistrationId: customerResult?.fourStopRegistrationId || "n/a",
    fourStopStatus: customerResult?.fourStopStatus || "n/a",
    fourStopScore: customerResult?.fourStopScore || "n/a",
    fourStopRec: customerResult?.fourStopRec || "n/a",
    fourStopDescription: customerResult?.fourStopDescription || "n/a",
    fourStopData: customerResult?.fourStopData || "n/a",
  };

  const geoCheck: GeoCheck = {
    anonymous_proxy:
      fourStopDataJson?.scrubber_results?.geo_check?.anonymous_proxy || "n/a",
    country_code:
      fourStopDataJson?.scrubber_results?.geo_check?.country_code || "n/a",
    ip_city: fourStopDataJson?.scrubber_results?.geo_check?.ip_city || "n/a",
    high_risk_country:
      fourStopDataJson?.scrubber_results?.high_risk_country || "n/a",
    ip_user_type: fourStopDataJson?.scrubber_results?.ip_user_type || "n/a",
    ip_org: fourStopDataJson?.scrubber_results?.ip_org || "n/a",
    city_postal_match:
      fourStopDataJson?.scrubber_results?.city_postal_match || "n/a",
  };

  const idCheck: IdCheck = {
    status: customerResult?.fourStopData?.status || "n/a",
    score: customerResult?.fourStopData?.score || "n/a",
    // the next lines contain data that is not available for now, ToDo: change once available
    document_type: customerResult?.fourStopData?.document_type || "n/a",
    identified_document:
      customerResult?.fourStopData?.identified_document || "n/a",
    document_valid: customerResult?.fourStopData?.document_valid || "n/a",
    not_a_sample: customerResult?.fourStopData?.not_a_sample || "n/a",
    not_expired: customerResult?.fourStopData?.not_expired || "n/a",
    original_document: customerResult?.fourStopData?.original_document || "n/a",
    // TODO
  };

  const screening: ScreeningToPrint = {
    code: fourStopDataJson?.scrubber_results?.compliance_watchlist3?.cw3_code,
    status:
      fourStopDataJson?.scrubber_results?.compliance_watchlist3?.cw3_status,
    matchStatus:
      fourStopDataJson?.scrubber_results?.compliance_watchlist3
        ?.cw3_match_status,
    totalHits:
      fourStopDataJson?.scrubber_results?.compliance_watchlist3?.cw3_total_hits,
    matchType:
      fourStopDataJson?.scrubber_results?.compliance_watchlist3
        ?.cw3_match_types,
    matchTypeDetails:
      fourStopDataJson?.scrubber_results?.compliance_watchlist3
        ?.cw3_match_types_details,
    ShareURL:
      fourStopDataJson?.scrubber_results?.compliance_watchlist3?.cw3_share_url,
  };

  if (!task) {
    return null;
  }
  return (
    <VerificationTemplate
      refetch={refetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {fsIndividual && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              label="fsIndividual__details"
              toPrint={fsIndividualToPrint}
            />
          </div>
        )}
        {addressDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={toPrintAddress}
              label="fsIndividual__address"
            />
          </div>
        )}
        {toPrintFsResult && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={toPrintFsResult}
              label="fsCustomer__result"
            />
          </div>
        )}
        <div className={classes.detailsWrapperThird}>
          {proofOfAddress &&
            proofOfAddress?.map((iDoc: Document) => (
              <div>
                <QuidTitle fontSize={20} textAlign="left">
                  {iDoc.type === "PROOF_OF_ADDRESS" ? "Proof of Address" : ""}
                </QuidTitle>
                <AttachmentWithoutPreview
                  attachment={iDoc}
                  downloadDoc={(params) =>
                    isHistory === undefined
                      ? fetchDocument({ ...params, taskId, taskKey })
                      : fetchHistoricalDocument({ ...params, taskId, taskKey })
                  }
                />
              </div>
            ))}
        </div>
        {mergedDocsInfo &&
          mergedDocsInfo.map((doc: Document) => (
            <div className={classes.detailsWrapperThird}>
              <QuidTitle fontSize={22} textAlign="left">
                {doc.type === "SELFIE"
                  ? "Selfie"
                  : doc.type === "ID_DOCUMENT"
                  ? "Id document "
                  : ""}
              </QuidTitle>
              <AttachmentWithoutPreview
                showAdditionalInfo={true}
                attachment={doc}
                downloadDoc={(params) =>
                  isHistory === undefined
                    ? fetchDocument({ ...params, taskId, taskKey })
                    : fetchHistoricalDocument({ ...params, taskId, taskKey })
                }
              />
            </div>
          ))}
        {fourStopDataJson?.scrubber_results && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock toPrint={geoCheck} label="fs__geo__check" />
          </div>
        )}
        {customerResult?.fourStopData && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock toPrint={idCheck} label="fs__id__check" />
          </div>
        )}
        {fourStopDataJson?.scrubber_results?.compliance_watchlist3 && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock toPrint={screening} label="fs__screening" />
          </div>
        )}
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectKycIndividualBox
            taskKey={taskKey}
            task={task}
            accept={(request) => acceptTask({ taskId, taskKey, request })}
            reject={(request) => rejectTask({ taskId, taskKey, request })}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default FourStopIndividualVerification;
