import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import MainTemplate from "templates/MainTemplate";
import useVerificationColumns from "shared/hooks/useVerificationColumns";
import IndividualIdentityVerification from "components/organisms/verifications/IndividualIdentityVerification";
import IndividualAddressVerification from "components/organisms/verifications/IndividualAddressVerification";
import IndividualFinancialVerification from "components/organisms/verifications/IndividualFinancialVerification";
import IndividualFinancialVerificationAccount from "components/organisms/verifications/IndividualFinancialVerificationAccount";
import IndividualEKycVerification from "components/organisms/verifications/IndividualEKycVerification";
import CorporateIdentityVerification from "components/organisms/verifications/CorporateIdentityVerification";
import PersonAddressVerification from "components/organisms/verifications/PersonAddressVerification";
import PersonIdentityVerification from "components/organisms/verifications/PersonIdentityVerification";
import PersonEKycVerification from "components/organisms/verifications/PersonEkycVerification";
import CorporateFinancialVerificationAccount from "components/organisms/verifications/CorporateFinancialVerificationAccount";
import CheckCompanyDetails from "components/organisms/verifications/CheckCompanyDetails";
import UploadCompanyFormationConfirmation from "components/organisms/verifications/UploadCompanyFormationConfirmation";
import CorporateCheckAccountDetailsExternal from "components/organisms/verifications/CorporateCheckAccountDetailsExternal";
import CorporateCheckAccountDetailsDirect from "components/organisms/verifications/CorporateCheckAccountDetailsDirect";
import IndividualCheckAccountDetailsExternal from "components/organisms/verifications/IndividualCheckAccountDetailsExternal";
import IndividualCheckAccountDetailsDirect from "components/organisms/verifications/IndividualCheckAccountDetailsDirect";
import FourStopCorporateVerification from "components/organisms/verifications/FourStopCorporateVerification";
import FourStopIndividualVerification from "components/organisms/verifications/FourStopIndividualVerification";
import ErrorHandlerVerification from "components/organisms/verifications/ErrorHandlerVerification";
import { handleFailure } from "resHandlers";
import { fetchTask } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import FourStopIndividualQualifyDocuments from "components/organisms/verifications/FourStopIndividualQualifyDocuments";

const ValidationTypeMatcher = () => {
  const { t } = useTranslation("validations");
  const {
    params: { taskId, taskDefinition: taskKey, history: isHistory },
  } = useRouteMatch<{
    taskId: string;
    history: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();
  const [task, setTask] = useState<TaskDetail>();

  const fetch = async () => {
    try {
      const res = await fetchTask<TaskDetail>({
        taskId,
        taskKey,
        isHistory,
      });
      setTask(res);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const [columns] = useVerificationColumns({
    detailMode: true,
    fetch,
    onUnassignRedirectTo: "/",
    isHistory,
    isTaskUnassignable: !isHistory,
  });
  const [accountsCols] = useVerificationColumns({
    detailMode: true,
    fetch,
    isTaskUnassignable: !isHistory,
    onUnassignRedirectTo: "/tasksaccounts",
    isHistory,
  });
  const [compFormCols] = useVerificationColumns({
    detailMode: true,
    fetch,
    isTaskUnassignable: !isHistory,
    onUnassignRedirectTo: "/taskscompanyformations",
    isHistory,
  });
  return (
    <MainTemplate>
      <div>
        <Typography variant="h5" className="title" align="center">
          {t(`title.${taskKey}`)}
        </Typography>
        {
          {
            IdentityVerification: (
              <IndividualIdentityVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            CorporateIdentityVerification: (
              <CorporateIdentityVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            PersonCorporateIdentityVerification: (
              <PersonIdentityVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            AddressVerification: (
              <IndividualAddressVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            PersonCorporateAddressVerification: (
              <PersonAddressVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            EKYCVerification: (
              <IndividualEKycVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            PersonCorporateEKYCVerification: (
              <PersonEKycVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            FinancialVerification: (
              <IndividualFinancialVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            FinancialVerificationAccount: (
              <IndividualFinancialVerificationAccount
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            CorporateFinancialVerificationAccount: (
              <CorporateFinancialVerificationAccount
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            CheckCompanyDetails: (
              <CheckCompanyDetails
                columns={compFormCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            UploadCompanyFormationConfirmation: (
              <UploadCompanyFormationConfirmation
                columns={compFormCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            CorporateCheckAccountDetailsExternal: (
              <CorporateCheckAccountDetailsExternal
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            CorporateCheckAccountDetailsDirect: (
              <CorporateCheckAccountDetailsDirect
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            IndividualCheckAccountDetailsExternal: (
              <IndividualCheckAccountDetailsExternal
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            IndividualCheckAccountDetailsDirect: (
              <IndividualCheckAccountDetailsDirect
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            fourStopCorporateVerification: (
              <FourStopCorporateVerification
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            fourStopIndividualVerification: (
              <FourStopIndividualVerification
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            fourStopIndividualDocumentQualifyVerification: (
              <FourStopIndividualQualifyDocuments
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            corporateVerificationErrorHander: (
              <ErrorHandlerVerification
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            IndividualVerificationError: (
              <ErrorHandlerVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            AccountOpeningErrorHandler: (
              <ErrorHandlerVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            AccountCreationError: (
              <ErrorHandlerVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            AccountOpeningError: (
              <ErrorHandlerVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            CompanyDetailsError: (
              <ErrorHandlerVerification
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            CompanyFormationError: (
              <ErrorHandlerVerification
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            IndividualIdentityVerificationError: (
              <ErrorHandlerVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            FourStopIndividualVerificationError: (
              <ErrorHandlerVerification
                columns={columns}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
            FourStopCorporateVerificationError: (
              <ErrorHandlerVerification
                columns={accountsCols}
                isHistory={isHistory}
                task={task}
                refetch={fetch}
              />
            ),
          }[taskKey]
        }
      </div>
    </MainTemplate>
  );
};

export default ValidationTypeMatcher;
