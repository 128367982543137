import React, { ReactNode, ReactElement, useState } from "react";
import { CellParams, ColDef, RowModel } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles, Button } from "@material-ui/core";
import QuidTitle from "components/atoms/QuidTitle";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import NationFormatter from "shared/formatters/NationFormatter";
import CreateAddressModal from "./CreateAddressModal";
import AddressDetailModal from "./AddressDetailModal";
import DetailButton from "components/atoms/DetailButton";
import { DocumentUploadParams } from "shared/hooks/useDocHandlers";
import { Address } from "entities/clients/AddressEntity";
import { CompanyDetails } from "entities/clients/CompanyEntity";
import {
  EnhancedAddress,
  CustomerDetails,
} from "entities/clients/CustomerBackofficeEntity";
import { Profile } from "entities/clients/ProfileEntity";
import StatusBadge from "components/atoms/StatusBadge";

const useStyles = makeStyles(() =>
  createStyles({
    addressSpacing: {
      marginTop: 30,
      marginBottom: 30,
    },
    status: {
      paddingLeft: "40px",
      lineheiht: "20px",
    },
    verified: {
      paddingLeft: "60px",
      lineheiht: "20px",
    },
    addressContainer: {
      marginTop: 30,
      minHeight: 500,
    },
    createAddressWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    container: {
      paddingBottom: "60px",
    },
  })
);

interface AddressProps {
  children?: ReactNode | ReactNode[];
  addresses: EnhancedAddress[];
  createAddress: (address: Address, use: string) => Promise<void>;
  refetch: () => void;
  customerProfiles?: Profile[];
  total?: number;
  isCustomer?: boolean;
  detailsCompany?: CompanyDetails;
  detailsCustomer?: CustomerDetails;
  onUploadDocuments: (values: DocumentUploadParams) => Promise<void>;
}

const AddressList: React.FC<AddressProps> = ({
  createAddress,
  customerProfiles,
  addresses,
  refetch,
  onUploadDocuments,
  isCustomer,
  total,
  detailsCompany,
  detailsCustomer,
}: AddressProps) => {
  const { t } = useTranslation("customer");
  const classes = useStyles();
  const [modalOpenCreate, setModalOpenCreate] = useState<boolean>(false);
  const [modalOpenDetails, setModalOpenDetails] = useState<boolean>(false);
  const [detailModalId, setDetailModalId] = useState<number>();
  const customerProfilesFiltered = customerProfiles?.filter(
    (d: any) =>
      d.type === (isCustomer ? "CUSTOMER" : "COMPANY") &&
      d.name ===
        (isCustomer
          ? `${detailsCustomer?.customer?.name} ${detailsCustomer?.customer?.surname}`
          : `${detailsCompany?.company?.companyName}`)
  );
  const onCloseModalCreate = () => {
    setModalOpenCreate(false);
  };

  const handleClickOpen = () => {
    setModalOpenCreate(true);
  };

  const onCloseModalDetails = () => {
    setModalOpenDetails(false);
    setDetailModalId(undefined);
  };

  const onDetailOpen = (addressId: number) => {
    setModalOpenDetails(true);
    setDetailModalId(addressId);
  };

  const onUpload = async (values: DocumentUploadParams) => {
    await onUploadDocuments(values);
  };

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "use",
      headerName: t("address__header_name__type"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "status",
      width: 170,
      headerName: t("address__header_name__status"),
      headerAlign: "center",
      align: "center",
      renderCell: (param: CellParams): ReactElement => {
        const status = (param?.row as RowModel)?.statuses[
          param?.row?.statuses.length - 1
        ]?.status;
        return (
          <div className={classes.status}>
            <StatusBadge status={status} />
          </div>
        );
      },
    },
    {
      field: "addressLine1",
      headerName: t("address__header_name__address_line_1"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "city",
      headerName: t("address__header_name__city_address"),
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "country",
      headerName: t("address__header_name__country"),
      headerAlign: "center",
      align: "center",
      renderCell: (param: CellParams): ReactElement => {
        const countryId = (param?.row as RowModel).country;
        return <NationFormatter countryId={countryId} />;
      },
      flex: 1,
    },
    {
      field: "verified",
      width: 170,
      headerName: t("listTable__header_name__verified"),
      headerAlign: "center",
      renderCell: (param: CellParams): ReactElement => {
        const status = (param?.row as RowModel)?.statuses[
          param?.row?.statuses.length - 1
        ]?.verified;
        return (
          <div className={classes.verified}>
            <StatusBadge status={status} />
          </div>
        );
      },
      flex: 1,
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => (
        <DetailButton onClick={() => onDetailOpen(params.row.id as number)} />
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <CreateAddressModal
        open={modalOpenCreate}
        isCustomer={isCustomer}
        createAddress={createAddress}
        customerProfiles={customerProfilesFiltered}
        refetch={refetch}
        onUpload={onUpload}
        onCloseModal={onCloseModalCreate}
      />
      <AddressDetailModal
        open={modalOpenDetails}
        addressId={detailModalId}
        addresses={addresses}
        onCloseModal={onCloseModalDetails}
      />
      <QuidTitle variant="h1">{t("address__head_title")}</QuidTitle>
      <div className={classes.addressContainer}>
        <div className={classes.createAddressWrapper}>
          <Button
            variant="contained"
            color="primary"
            component="label"
            onClick={handleClickOpen}
          >
            {t("address__button__new__address")}
          </Button>
        </div>
        <QuidDataGrid
          sortModel={[{ field: "id", sort: "desc" }]}
          rowCount={total}
          columns={columns}
          rows={addresses}
          pageSize={total}
        />
      </div>
    </div>
  );
};

export default AddressList;
