import { Grid, makeStyles, Typography } from "@material-ui/core";
import ArrowIcon from "components/atoms/icons/ArrowIcon";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
  block: {
    padding: 15,
    "& :hover": {
      cursor: "pointer",
    },
  },
  blockInside: {
    display: "flex",
    alignItems: "center",
    background: "#fff",
    padding: "15px",
    borderRadius: 31,
    boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.1)",
    justifyContent: "space-between",
    minHeight: "50px",
  },
  blockItem: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 10,
  },
  blockItemTitleFull: {
    width: "100%",
    paddingRight: 15,
    paddingBottom: 5,
  },
  blockItemTitleHalf: {
    width: "50%",
    paddingRight: 15,
  },
  columnCard: {
    display: "flex",
    flexDirection: "column",
  },
  horizontalCard: {
    display: "flex",
  },
}));

interface Item {
  label: string;
  path: string;
}

interface InfoBoxProps {
  isColumn: boolean;
  items: Item[];
  from?: string;
}

const CardDetails: React.FC<InfoBoxProps> = ({ isColumn, items, from }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      {isColumn &&
        items?.map((item) => (
          <Grid item xs={12}>
            <div
              className={classes.block}
              onClick={() =>
                history.push({
                  pathname: item?.path,
                  state: { from },
                })
              }
            >
              <div className={classes.blockInside}>
                <>
                  <Typography variant="body1" color="textSecondary">
                    {item?.label}
                  </Typography>
                  <ArrowIcon></ArrowIcon>
                </>
              </div>
            </div>
          </Grid>
        ))}
      {!isColumn &&
        items?.map((item) => (
          <Grid item xs={4}>
            <div
              className={classes.block}
              onClick={() =>
                history.push({
                  pathname: item?.path,
                  state: { from },
                })
              }
            >
              <div className={classes.blockInside}>
                <>
                  <Typography variant="body1" color="textSecondary">
                    {item?.label}
                  </Typography>
                  <ArrowIcon></ArrowIcon>
                </>
              </div>
            </div>
          </Grid>
        ))}
    </>
  );
};

export default CardDetails;
