import {
  FetchTransfersAccountsParams,
  AccountResponse,
  AccountTransfersDestinationParams,
  CreateAccountRequest,
  ValidateAccountParams,
  FetchAccountParams,
  FetchUsersAccount,
  UsersAccounts,
  AccountFromBOResponse,
  DownloadAccountParams,
  GetRateRequest,
  RateResponse,
} from "entities/accounts/Account";
import {
  FetchBalanceParams,
  BalanceResponse,
  FetchBalanceByAccountIdParams,
  BalancesResponse,
  FetchBalancesParams,
  DownloadBalanceParams,
} from "entities/accounts/Balance";
import {
  FetchTransactionDetailParams,
  TransactionDetailResponse,
  FetchTransactionParams,
  TransactionResponse,
  FetchAccountTransactionParams,
  AccountTransactionResponse,
  AcceptTransactionParams,
  RejectTransactionParams,
  DownloadTransactionParams,
} from "entities/accounts/Transaction";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";

import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function fetchTransfersAccounts(
  params: FetchTransfersAccountsParams
): Promise<AccountResponse[]> {
  try {
    const res = await apiClient.get<AccountResponse[]>(
      "/accounts/v1/accounts/transfers/accounts",
      "account",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchAccountTransfersDestination(
  params: AccountTransfersDestinationParams
): Promise<AccountResponse[]> {
  try {
    const res = await apiClient.get<AccountResponse[]>(
      "/accounts/v1/accounts/transfers/destination",
      "account",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchTransactionDetailsByTransactionId(
  params: FetchTransactionDetailParams
): Promise<TransactionDetailResponse> {
  try {
    const res = await apiClient.get<TransactionDetailResponse>(
      `/accounts/v1/accounts/bo/${params.balance_id}/transactions/${params.transaction_id}`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function createAccount(
  params: CreateAccountRequest
): Promise<AccountResponse> {
  try {
    const res = await apiClient.post<AccountResponse>(
      `/accounts/v1/accounts/`,
      "account",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createAccountFromBO(
  contract_id: string,
  profile_id: number
): Promise<AccountFromBOResponse> {
  try {
    const res = await apiClient.post<AccountFromBOResponse>(
      `accounts/v1/accounts/bo/create`,
      "account",
      { contract_id, profile_id }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getAccountRate(
  params: GetRateRequest
): Promise<RateResponse> {
  try {
    const res = await apiClient.get<RateResponse>(
      `/accounts/v1/conversion/management/rates`,
      "account",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function createTemporaryRate(
  rateId: number,
  partyId: number
): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/accounts/v1/conversion/management/temporaryRate/${rateId}?partyId=${partyId}`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function validateAccountByAccountId(
  params: ValidateAccountParams
): Promise<void> {
  try {
    await apiClient.post(
      `/accounts/v1/accounts/validate/${params.account_id}`,
      "account"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchAccountByAccountId(
  params: FetchAccountParams
): Promise<AccountResponse> {
  try {
    const res = await apiClient.get<AccountResponse>(
      `/accounts/v1/accounts/${params.accountId}`,
      "account"
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchBalanceByBalanceId(
  params: FetchBalanceParams
): Promise<BalanceResponse> {
  try {
    const res = await apiClient.get<BalanceResponse>(
      `/accounts/v1/balances/${params.balance_id}`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchBalancesByAccountId(
  params: FetchBalanceByAccountIdParams
): Promise<BalancesResponse> {
  try {
    const res = await apiClient.get<BalancesResponse>(
      `/accounts/v1/balances/accounts/${params.account_id}`,
      "account"
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function fetchAccountTransactionsByBalanceId(
  params: FetchTransactionParams
): Promise<TransactionResponse> {
  try {
    const { balance_id, ...otherParams } = params;
    const res = await apiClient.get<TransactionResponse>(
      `/accounts/v1/accounts/${balance_id}/transactions`,
      "account",
      otherParams
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function getAccountBalances(
  params: FetchBalancesParams
): Promise<BalancesResponse> {
  try {
    const res = await apiClient.get<BalancesResponse>(
      "/accounts/v1/balances",
      "account",
      params
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getCustomerWalletsByPartyId(
  params: FetchBalancesParams
): Promise<BalancesResponse> {
  try {
    const res = await apiClient.get<BalancesResponse>(
      `/accounts/v1/balances/bo/${params.partyId}/wallets`,
      "account",
      params
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchUserAccount(
  params: FetchUsersAccount
): Promise<UsersAccounts> {
  try {
    const res = await apiClient.get<UsersAccounts>(
      "/accounts/v1/accounts/bo",
      "account",
      params
    );

    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function fetchAccountTransactions(
  params: FetchAccountTransactionParams
): Promise<AccountTransactionResponse> {
  try {
    const res = await apiClient.get<AccountTransactionResponse>(
      "/accounts/v1/accounts/transactions",
      "account",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function acceptTransaction(
  params: AcceptTransactionParams
): Promise<void> {
  try {
    await apiClient.post(
      `/accounts/v1/accounts/transactions/approve/${params.transaction_id}`,
      "account"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function rejectTransaction(
  params: RejectTransactionParams
): Promise<void> {
  try {
    await apiClient.post(
      `/accounts/v1/accounts/transactions/reject/${params.transaction_id}`,
      "account"
    );
    return;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function downloadBalancesDocument(
  params: DownloadBalanceParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/accounts/v1/balances/exportBalances`,
      "account",
      { ...(params.type && { type: params?.type }) },
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function downloadAccountDocument(
  params: DownloadAccountParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/accounts/v1/accounts/bo/csv`,
      "account",
      params,
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function downloadAccountBalancesDocument(
  params: DownloadTransactionParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/accounts/v1/balances/accounts/${params.account_id}/exportBalances`,
      "account",
      params,
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function downloadTransactionsDocument(
  params: DownloadTransactionParams
): Promise<FilePayload> {
  try {
    const res = await apiClient.get<FilePayload>(
      `/accounts/v1/accounts/exporttransactions`,
      "account",
      params,
      {
        responseType: "arraybuffer",
        transformResponse: (data: any, headers?: any): any => {
          return {
            data: {
              buffer: data,
              contentType: headers["content-type"],
            },
          } as FilePayload;
        },
      }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
