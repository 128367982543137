import React, { useState } from "react";
import FullScreenModal from "components/atoms/FullScreenModal";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import ApproveRejectButtons from "components/atoms/ApproveRejectButtons";
import { getPartiesCountries } from "store/reducers/app.reducer";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { acceptTransaction, rejectTransaction } from "api/accounts";
import { TransactionDetailResponse } from "entities/accounts/Transaction";
import {
  PayerDetails,
  TransactionDetails,
  FeeInfo,
  ConversionDetails,
  TansferDetails,
  InboundDetails,
  InboundSenderDetails,
  BeneficiaryDetails,
  BeneficiaryBankDetails,
  beneficiaryDetails,
} from "entities/accounts/Payment";
import { formatDate } from "utils";

interface PaymentDetailModalProps {
  open: boolean | undefined;
  setOpen: (open: boolean) => void;
  data?: TransactionDetailResponse;
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flex1: {
    flex: 1,
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
}));

const PaymentDetailModal: React.FC<PaymentDetailModalProps> = ({
  setOpen,
  open,
  data,
}) => {
  const { t } = useTranslation("payments");
  const classes = useStyles();
  const countries = useSelector(getPartiesCountries);
  const transaction = data?.transaction;
  const [disabledActions, setDisabledActions] = useState(false);
  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  const payerDetailsToPrint: PayerDetails =
    data?.payment_detail?.payer?.legal_entity_type === "company"
      ? {
          company_name: data?.payment_detail?.payer?.company_name || "n/a",
          postcode: data?.payment_detail?.payer?.postcode || "n/a",
          address: data?.payment_detail?.payer?.address || "n/a",
          city: data?.payment_detail?.payer?.city || "n/a",
          country: data?.payment_detail?.payer?.country
            ? getCountry(data?.payment_detail?.payer?.country as string)
            : "n/a",
          balance_id: transaction?.balance_id || "n/a",
          toonieId: data?.payment_detail?.payer?.payer_id || "n/a",
          walletId: data?.payment_detail?.payer?.payer_id || "n/a",
        }
      : {
          first_name: data?.payment_detail?.payer?.first_name || "n/a",
          last_name: data?.payment_detail?.payer?.last_name || "n/a",
          postcode: data?.payment_detail?.payer?.postcode || "n/a",
          address: data?.payment_detail?.payer?.address || "n/a",
          city: data?.payment_detail?.payer?.city || "n/a",
          country: data?.payment_detail?.payer?.country
            ? getCountry(data?.payment_detail?.payer?.country as string)
            : "n/a",
          balance_id: transaction?.balance_id || "n/a",
          toonieId: data?.payment_detail?.payer?.payer_id || "n/a",
          walletId: data?.payment_detail?.payer?.payer_id || "n/a",
        };

  const transactionDetailsToPrint: TransactionDetails = {
    transaction_id: transaction?.id || "n/a",
    type: transaction?.type || "n/a",
    status: transaction?.status || "n/a",
    failure_reason: transaction?.failure_reason || "n/a",
    direction: transaction?.direction || "n/a",
    provider: transaction?.provider || "n/a",
    provider_reference: transaction?.provider_reference || "n/a",
    unique_request_id: data?.payment_detail?.unique_request_id || "n/a",
    amount: transaction?.amount || "n/a",
    chargetype: data?.payment_detail?.charge_type || "n/a",
    created: transaction
      ? formatDate(transaction?.created_at as string, "dd/MM/yyyy")
      : "",
    completed: transaction
      ? formatDate(transaction?.completed_at as string, "dd/MM/yyyy")
      : "",
    paymentDescription: "n/a",
    paymentReason: transaction?.reason || "n/a",
  };

  const beneficiaryDetails: beneficiaryDetails = {
    name: data?.transaction?.customer_name || "n/a",
    type: data?.transaction?.customer_type || "n/a",
    toonieId: data?.transaction?.customer_id || "n/a",
    walletId: data?.transaction?.balance_id || "n/a",
  };

  const feeInfoToPrint: FeeInfo = {
    fee_amount:
      MoneyFormatter(
        data?.payment_detail?.fee_amount as number,
        data?.payment_detail?.fee_currency as DineroFactory.Currency
      ) || "n/a",
    fee_currency: data?.payment_detail?.fee_currency || "n/a",
    fee_reason: data?.payment_detail?.fee_reason || "n/a",
    fee_rules: data?.payment_detail?.fee_rules || "n/a",
  };

  const conversionDetailsToPrint: ConversionDetails = {
    sell_balance_id: data?.conversion_detail?.sell_balance_id || "n/a",
    buy_balance_id: data?.conversion_detail?.buy_balance_id || "n/a",
    quid_markup: `${data?.conversion_detail?.quid_markup} %` || "n/a",
    fx_volatility: `${data?.conversion_detail?.fx_volatility} %` || "n/a",
    provider_rate: data?.conversion_detail?.provider_rate || "n/a",
    client_rate: data?.conversion_detail?.client_rate || "n/a",
    indicative_exchange_rate:
      data?.conversion_detail?.indicative_exchange_rate || "n/a",
    volatility_margin: data?.conversion_detail?.volatility_margin || "n/a",
    buy_currency:
      MoneyFormatter(
        data?.conversion_detail?.client_buy_amount as number,
        data?.conversion_detail?.buy_currency as DineroFactory.Currency
      ) || "n/a",
    sell_currency:
      MoneyFormatter(
        data?.conversion_detail?.client_sell_amount as number,
        data?.conversion_detail?.sell_currency as DineroFactory.Currency
      ) || "n/a",
    short_reference: data?.conversion_detail?.short_reference || "n/a",
    status: data?.conversion_detail?.status || "n/a",
    created_at: (
      <div className={classes.dateType}>
        {formatDate(
          data?.conversion_detail?.created_at as string,
          "dd/MM/yyyy"
        )}
        <small>
          {formatDate(data?.conversion_detail?.created_at as string, "HH:mm")}
        </small>
      </div>
    ),
    transferred_at: (
      <div className={classes.dateType}>
        {formatDate(
          data?.conversion_detail?.transferred_at as string,
          "dd/MM/yyyy"
        )}
        <small>
          {formatDate(
            data?.conversion_detail?.transferred_at as string,
            "HH:mm"
          )}
        </small>
      </div>
    ),
  };

  const transferDetailsToPrint: TansferDetails = {
    reference: data?.transfer_detail?.reference || "n/a",
    currency:
      MoneyFormatter(
        data?.transfer_detail?.amount as number,
        data?.transfer_detail?.currency as DineroFactory.Currency
      ) || "n/a",
    reason: data?.transfer_detail?.reason || "n/a",
  };

  const inboundFundsDetailsToPrint: InboundDetails = {
    reference: data?.incoming_fund_detail?.reference || "n/a",
    action: data?.incoming_fund_detail?.action || "n/a",
    type: data?.incoming_fund_detail?.type || "n/a",
    currency:
      MoneyFormatter(
        data?.incoming_fund_detail?.amount as number,
        data?.incoming_fund_detail?.currency as DineroFactory.Currency
      ) || "n/a",
    reason: data?.incoming_fund_detail?.reason || "n/a",
    status: data?.incoming_fund_detail?.status || "n/a",
    funding_id: data?.incoming_fund_detail?.funding_id || "n/a",
  };

  const inboundFundsSenderDetailsToPrint: InboundSenderDetails = {
    sender_id: data?.incoming_fund_detail?.sender?.sender_id || "n/a",
    name: data?.incoming_fund_detail?.sender?.name || "n/a",
    address: data?.incoming_fund_detail?.sender?.address || "n/a",
    country: data?.incoming_fund_detail?.sender?.country
      ? getCountry(data.incoming_fund_detail.sender.country as string)
      : "n/a",
    account_number_iban:
      data?.incoming_fund_detail?.sender?.account_number_iban || "n/a",
    additional_information:
      data?.incoming_fund_detail?.sender?.additional_information || "n/a",
  };

  const beneficiaryDetailsToPrint: BeneficiaryDetails = {
    holderName:
      data?.payment_detail?.beneficiary_info?.bank_account_holder_name || "n/a",
    name: data?.payment_detail?.beneficiary_info?.first_name || "n/a",
    surname: data?.payment_detail?.beneficiary_info?.last_name || "n/a",
    beneficiaryID:
      data?.payment_detail?.beneficiary_info?.beneficiary_id || "n/a",
    beneficiaryEntityType:
      data?.payment_detail?.beneficiary_info?.beneficiary_entity_type || "n/a",
    contactId: data?.payment_detail?.beneficiary_info?.contactId || "n/a",
    iban: data?.payment_detail?.beneficiary_info?.iban || "n/a",
    postcode: data?.payment_detail?.beneficiary_info?.postcode || "n/a",
    address:
      data?.payment_detail?.beneficiary_info?.beneficiary_address || "n/a",
    city: data?.payment_detail?.beneficiary_info?.city || "n/a",
    country: data?.payment_detail?.beneficiary_info?.beneficiary_country
      ? getCountry(
          data?.payment_detail?.beneficiary_info?.beneficiary_country as string
        )
      : "n/a",
  };

  const beneficiaryBankDetailsToPrint: BeneficiaryBankDetails = {
    bank_name: data?.payment_detail?.beneficiary_info?.bank_name || "n/a",
    iban: data?.payment_detail?.beneficiary_info?.iban || "n/a",
    routingCode1:
      `${data?.payment_detail?.beneficiary_info?.routing_code_type_1} / ${data?.payment_detail?.beneficiary_info?.routing_code_value_1}` ||
      "n/a",
    routingCode2:
      `${data?.payment_detail?.beneficiary_info?.routing_code_type_2} / ${data?.payment_detail?.beneficiary_info?.routing_code_value_2}` ||
      "n/a",
    accountNumber:
      data?.payment_detail?.beneficiary_info?.account_number || "n/a",
    bic_swift: data?.payment_detail?.beneficiary_info?.bic_swift || "n/a",
  };

  const onAccept = async () => {
    setDisabledActions(true);
    try {
      await acceptTransaction({
        transaction_id: data?.transaction.id as number,
      });
      handleSuccessfulMessage("Action received");
      setOpen(false);
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setDisabledActions(false);
    }
  };

  const onReject = async () => {
    setDisabledActions(true);

    try {
      await rejectTransaction({
        transaction_id: data?.transaction.id as number,
      });
      handleSuccessfulMessage("Action received");
      setOpen(false);
    } catch (err: any) {
      handleFailure(err);
    } finally {
      setDisabledActions(false);
    }
  };

  const isPendingStatus =
    data?.transaction?.status === "pending_to_review" &&
    data?.transaction?.type !== "conversion";

  return (
    <FullScreenModal open={open} onClose={() => setOpen(false)}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt35}>
            <Typography variant="h1" className={classes.title}>
              {data?.transaction?.type === "conversion"
                ? t("conversion__details__title")
                : t("payment__details__title")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justify="center"
          className={classes.mt20}
        >
          {data?.transaction?.type === "payment" && (
            <Grid item xs={3}>
              <DetailsBlock
                label="details__transaction__details"
                toPrint={transactionDetailsToPrint}
              />
              <DetailsBlock
                label="details__payer__details"
                toPrint={payerDetailsToPrint}
              />
            </Grid>
          )}
          {data?.transaction?.type === "payment" && (
            <Grid item xs={3}>
              <DetailsBlock
                label="details__beneficiary__details"
                toPrint={beneficiaryDetailsToPrint}
              />
            </Grid>
          )}
          {data?.transaction?.type === "payment" && (
            <Grid item xs={3}>
              <DetailsBlock label="fee_info" toPrint={feeInfoToPrint} />
              <DetailsBlock
                label="details__beneficiary__bank__details"
                toPrint={beneficiaryBankDetailsToPrint}
              />
            </Grid>
          )}
          {data?.transaction?.type === "transfer" && (
            <Grid item xs={3}>
              <DetailsBlock
                label="details__transaction__details"
                toPrint={transactionDetailsToPrint}
              />
            </Grid>
          )}
          {data?.transaction?.type === "transfer" && (
            <Grid item xs={3}>
              <DetailsBlock
                label="details__transfer__details"
                toPrint={transferDetailsToPrint}
              />
            </Grid>
          )}
          {data?.transaction?.type === "transfer" && (
            <Grid item xs={3}>
              <DetailsBlock
                label="details__beneficiary__details"
                toPrint={beneficiaryDetails}
              />
            </Grid>
          )}
          {data?.transaction?.type === "inbound_funds" && (
            <Grid item xs={3}>
              <DetailsBlock
                label="details__transaction__details"
                toPrint={transactionDetailsToPrint}
              />
            </Grid>
          )}
          {data?.transaction?.type === "inbound_funds" && (
            <Grid item xs={3}>
              <DetailsBlock
                label="details__inboundFundsSender__details"
                toPrint={inboundFundsSenderDetailsToPrint}
              />
              <DetailsBlock
                label="details__inboundFunds__details"
                toPrint={inboundFundsDetailsToPrint}
              />
            </Grid>
          )}
          {data?.transaction?.type === "conversion" && (
            <Grid item xs={3}>
              <DetailsBlock
                label="details__transaction__details"
                toPrint={transactionDetailsToPrint}
              />
            </Grid>
          )}
          {data?.transaction?.type === "conversion" && (
            <Grid item xs={3}>
              <DetailsBlock
                label="details__conversion__details"
                toPrint={conversionDetailsToPrint}
              />
            </Grid>
          )}
          {data?.transaction?.type === "inbound_funds" && (
            <Grid item xs={3}>
              <DetailsBlock
                label="details__wallet_holder"
                toPrint={beneficiaryDetails}
              />
            </Grid>
          )}
        </Grid>

        <div style={{ flex: "0 0 15%", alignItems: "center", display: "flex" }}>
          {isPendingStatus && (
            <ApproveRejectButtons
              disabled={disabledActions}
              onAccept={onAccept}
              onReject={onReject}
            />
          )}
        </div>
      </div>
    </FullScreenModal>
  );
};

export default PaymentDetailModal;
