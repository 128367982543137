import {
  BeneficiariesParams,
  BeneficiaryResponse,
  CurrenciesResponse,
  PaymentDetailResponse,
  PaymentParams,
  PaymentsListParams,
  PaymentsResponse,
  SmartContractBalanceDetailResponse,
  TransactionResponse,
  TransferParams,
  TransferResponse,
  WalletDetailResponse,
  WalletListParams,
  WalletResponse,
} from "../entities/Crypto";
import { Customers } from "../entities/Parties";
import { apiClient } from "../utils/api/apiClient";

export async function fetchCryptoWalletsManagement(
  params: WalletListParams
): Promise<WalletResponse> {
  try {
    const res = await apiClient.get<WalletResponse>(
      `/crypto/v1/walletsManagement/`,
      "crypto",
      {
        size: params.size,
        page: params.page,
        partyId: params.partyId,
        walletId: params.walletId,
        walletAddress: params.walletAddress,
      }
    );

    const resCustomers = await apiClient.get<Customers>(
      `/parties/v1/customers/`,
      "parties",
      {
        pageSize: 10000000,
      }
    );

    return {
      ...res,
      wallets: res.wallets.map((wallet) => {
        return {
          ...wallet,
          customer: resCustomers.customerList.find(
            (customer) => customer.id === wallet.partyId
          ),
        };
      }),
    };
  } catch (err: any) {
    throw err;
  }
}

export async function fetchCryptoWalletManagementByPartyId(
  partyId: number
): Promise<WalletDetailResponse> {
  try {
    const res = await apiClient.get<WalletDetailResponse>(
      `/crypto/v1/walletsManagement/parties/${partyId}`,
      "crypto"
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function fetchCryptoWalletManagementTransactionsByPartyId(
  partyId: number
): Promise<TransactionResponse> {
  try {
    const res = await apiClient.get<TransactionResponse>(
      `/crypto/v1/walletsManagement/parties/${partyId}/transactions`,
      "crypto"
    );

    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function fetchCryptoPaymentsManagement(
  params: PaymentsListParams
): Promise<PaymentsResponse> {
  try {
    const res = await apiClient.get<PaymentsResponse>(
      `/crypto/v1/paymentsManagement/`,
      "crypto",
      {
        size: params.size,
        page: params.page,
        SmartContractId: params.SmartContractId,
        transactionId: params.transactionId,
        amount: params.amount,
        date: params.date,
        currency: params.currency,
      }
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function postCryptoPaymentsManagement(
  params: PaymentParams
): Promise<PaymentParams> {
  try {
    const res = await apiClient.post<PaymentParams>(
      `/crypto/v1/paymentsManagement/`,
      "crypto",
      {
        walletIdFrom: params?.walletIdFrom,
        beneficiaryWalletIdTo: params?.beneficiaryWalletIdTo,
        amount: params?.amount,
        decimalPlaces: params?.decimalPlaces,
        network: params?.network,
        currency: params?.currency,
        smartContractId: params?.smartContractId,
        reason: params?.reason,
      }
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function postCryptoBeneficiariesManagement(
  params: BeneficiariesParams
): Promise<BeneficiariesParams> {
  try {
    const res = await apiClient.post<BeneficiariesParams>(
      `/crypto/v1/beneficiariesManagement/`,
      "crypto",
      {
        name: params?.name,
        note: params?.note,
        partyId: params?.partyId,
        network: params?.network,
        currency: params?.currency,
        walletAddress: params?.walletAddress,
      }
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function fetchCryptoPaymentManagementDetails(
  paymentId: any
): Promise<PaymentDetailResponse> {
  try {
    const res = await apiClient.get<PaymentDetailResponse>(
      `/crypto/v1/paymentsManagement/${paymentId}/details`,
      "crypto"
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function fetchCryptoSmartContractBySmartContractId(
  walletId: string | number,
  smartContractId: string | number
): Promise<SmartContractBalanceDetailResponse> {
  try {
    const res = await apiClient.get<SmartContractBalanceDetailResponse>(
      `
      /crypto/v1/wallets/${walletId}/balances/smartContracts/${smartContractId}`,
      "crypto"
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function fetchCryptoCurrenciesEnums(): Promise<CurrenciesResponse> {
  try {
    const res = await apiClient.get<CurrenciesResponse>(
      `/crypto/v1/walletsManagement/currencies`,
      "crypto"
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function fetchCryptoBeneficiariesManagementByPartyId(
  partyId: string | number
): Promise<BeneficiaryResponse> {
  try {
    const res = await apiClient.get<BeneficiaryResponse>(
      `/crypto/v1/beneficiariesManagement/${partyId}`,
      "crypto"
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function postCryptoTransfer(
  params: TransferParams
): Promise<TransferParams> {
  try {
    const res = await apiClient.post<TransferResponse>(
      `/crypto/v1/crypto/transfer`,
      "crypto",
      {
        owner: params?.owner,
        amount: params?.amount,
        network: params?.network,
        tokenId: params?.tokenId,
        uintAmount: params?.uintAmount,
        metadataUri: params?.metadataUri,
        projectName: params?.projectName,
        walletAddress: params?.walletAddress,
        destinationAddress: params?.destinationAddress,
        smartContractAddress: params?.smartContractAddress,
      }
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}
