import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Grid, makeStyles } from "@material-ui/core";
import MainTemplate from "templates/MainTemplate";
import { handleFailure } from "resHandlers";
import { useTranslation } from "react-i18next";
import QuidTitle from "components/atoms/QuidTitle";
import { fetchCompanyDetails } from "services/companies";
import { CompanyDetails } from "entities/clients/CompanyEntity";
import InfoBox from "components/molecules/InfoBox";
import clsx from "clsx";
import { useHistory } from "react-router";
import CardDetails from "components/molecules/CardDetails";
import { formatDate } from "utils";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginBottom: "100px",
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
  container: {
    minHeight: "100px",
  },
  verticalAlign: {
    alignItems: "center",
  },
  actionButtons: {
    marginBottom: 30,
    flexGrow: 3,
    display: "flex",
    alignItems: "center",
    paddingTop: "80px",
    justifyContent: "center",
  },
  buttonMargin: {
    "&--left": {
      marginLeft: 16,
    },
    "&--right": {
      marginRight: 16,
    },
  },
}));

const detailsFirstState: CompanyDetails = ({
  company: null,
  products: null,
  directors: null,
  addresses: null,
  financialData: null,
  profiles: null,
  documents: null,
  history: null,
} as unknown) as CompanyDetails;

interface Item {
  label: string;
  path: string;
}

const CompanyDetail: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("company");
  const companyId: number = parseInt(match.params.id, 10);
  const history = useHistory();
  const classes = useStyles();
  const btnMl = clsx(`${classes.buttonMargin}--left`);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(detailsFirstState);
  const customerId = details.company?.customer?.id;

  const [rightSections, setRightSections] = useState<Item[]>([]);
  const [bottomSections, setBottomSections] = useState<Item[]>([]);

  useEffect(() => {
    if (companyId) {
      void getCompanyDetails(companyId);
    }

    switch (process.env.REACT_APP_KEYCLOAK_REALM) {
      case "quid":
        setRightSections(cardLabelsCustomerQuidColumn);
        setBottomSections(cardLabelsCustomerQuidHorizontal);
        break;
      case "toonie":
        setRightSections(cardLabelsCustomerToonieColumn);
        setBottomSections(cardLabelsCustomerToonieHorizontal);
        break;
      case "elviria":
        setRightSections(cardLabelsCustomerElviriaColumn);
        setBottomSections(cardLabelsCustomerElviriaHorizontal);
        break;
      case "strfiinvest":
        setRightSections(cardLabelsCustomerElviriaColumn);
        setBottomSections(cardLabelsCustomerElviriaHorizontal);
        break;
      case "vaffa":
        setRightSections(cardLabelsCustomerVaffaColumn);
        setBottomSections(cardLabelsCustomerVaffaHorizontal);
        break;
      case "ats":
        setRightSections(cardLabelsCustomerVaffaColumn);
        setBottomSections(cardLabelsCustomerVaffaHorizontal);
        break;
    }
  }, [companyId]);

  const companyDetailsData = [
    {
      title: t("company_details_info_id"),
      value: details?.company?.id || "n/a",
    },
    {
      title: t("company_details_info_name"),
      value: details?.company?.companyName || "n/a",
    },
    {
      title: t("company_details_info_number"),
      value: details?.company?.companyNumber || "n/a",
    },
    {
      title: t("company_details_info_legal_entity_type"),
      value: details?.company?.legalEntityType || "n/a",
    },
    {
      title: t("company_details_info_legal_entity_value"),
      value: details?.company?.legalEntityValue || "n/a",
    },
    {
      title: t("company_details_info_incorporation_date"),
      value: formatDate(details?.company?.incorporationDate) || "n/a",
    },
    {
      title: t("company_details_info_vat_number"),
      value: details?.company?.vatNumber || "n/a",
    },
    {
      title: t("company_details_info_trading_name"),
      value: details?.company?.tradingName || "n/a",
    },
  ];

  const companyDetailsDataQuid = [
    {
      title: t("company_details_info_id"),
      value: details?.company?.id || "n/a",
    },
    {
      title: t("company_details_info_name"),
      value: details?.company?.companyName || "n/a",
    },
    {
      title: t("company_details_info_number"),
      value: details?.company?.companyNumber || "n/a",
    },
    {
      title: t("company_details_info_legal_entity_type"),
      value: details?.company?.legalEntityType || "n/a",
    },
    {
      title: t("company_details_info_legal_entity_value"),
      value: details?.company?.legalEntityValue || "n/a",
    },
    {
      title: t("company_details_info_incorporation_date"),
      value: formatDate(details?.company?.incorporationDate) || "n/a",
    },
    {
      title: t("company_details_info_vat_number"),
      value: details?.company?.vatNumber || "n/a",
    },
  ];

  const cardLabelsCustomerQuidColumn = [
    {
      label: t("company_details_card_products"),
      path: `/companies/${companyId}/products`,
    },
  ];

  const cardLabelsCustomerQuidHorizontal = [
    {
      label: t("company_details_card_addresses"),
      path: `/companies/${companyId}/addresses`,
    },
    {
      label: t("company_details_card_accounts"),
      path: `/companies/${companyId}/accounts`,
    },
    {
      label: t("company_details_card_documents"),
      path: `/companies/${companyId}/documents`,
    },
    {
      label: t("company_details_card_directors"),
      path: `/companies/${companyId}/directors`,
    },
    {
      label: t("company_details_card_ubo"),
      path: `/companies/${companyId}/ubo`,
    },
    {
      label: t("company_details_card_financial"),
      path: `/companies/${companyId}/financial`,
    },
  ];

  const cardLabelsCustomerToonieColumn = [
    {
      label: t("company_details_card_products"),
      path: `/companies/${companyId}/products`,
    },
  ];

  const cardLabelsCustomerToonieHorizontal = [
    {
      label: t("company_details_card_addresses"),
      path: `/companies/${companyId}/addresses`,
    },
    {
      label: t("company_details_card_wallets"),
      path: `/companies/${companyId}/wallets`,
    },
    {
      label: t("company_details_card_accounts"),
      path: `/companies/${companyId}/accounts`,
    },
    {
      label: t("company_details_card_documents"),
      path: `/companies/${companyId}/documents`,
    },
    {
      label: t("company_details_card_directors"),
      path: `/companies/${companyId}/directors`,
    },
    {
      label: t("company_details_card_ubo"),
      path: `/companies/${companyId}/ubo`,
    },
    {
      label: t("company_details_card_financial"),
      path: `/companies/${companyId}/financial`,
    },
    {
      label: t("company_details_card_offers"),
      path: `/companies/${companyId}/offers`,
    },
  ];

  const cardLabelsCustomerElviriaColumn = [
    {
      label: t("company_details_card_products"),
      path: `/companies/${companyId}/products`,
    },
  ];

  const cardLabelsCustomerElviriaHorizontal = [
    {
      label: t("company_details_card_addresses"),
      path: `/companies/${companyId}/addresses`,
    },
    {
      label: t("company_details_card_accounts"),
      path: `/companies/${companyId}/accounts`,
    },
    {
      label: t("company_details_card_documents"),
      path: `/companies/${companyId}/documents`,
    },
    {
      label: t("company_details_card_directors"),
      path: `/companies/${companyId}/directors`,
    },
    {
      label: t("company_details_card_ubo"),
      path: `/companies/${companyId}/ubo`,
    },
    {
      label: t("company_details_card_financial"),
      path: `/companies/${companyId}/financial`,
    },
    {
      label: t("company_details_card_investments"),
      path: `/companies/${companyId}/investments/info`,
    },
    {
      label: t("company_details_card_investments_preferences"),
      path: `/companies/${companyId}/investments/preferences`,
    },
  ];

  const cardLabelsCustomerVaffaColumn = [
    {
      label: t("company_details_card_products"),
      path: `/companies/${companyId}/products`,
    },
  ];
  const cardLabelsCustomerVaffaHorizontal = [
    {
      label: t("company_details_card_wallets"),
      path: `/customers/${companyId}/crypto/wallets`,
    },
  ];

  const getCompanyDetails = async (id: number | string): Promise<void> => {
    setLoading(true);
    try {
      const companyDetails = await fetchCompanyDetails({
        companyId: id as number,
      });
      setDetails(companyDetails);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainTemplate>
      <div className={classes.root}>
        <QuidTitle>{t("page__title__companyDetails")}</QuidTitle>
        <Grid container className={(classes.container, classes.verticalAlign)}>
          <Grid item xs={8}>
            {process.env.REACT_APP_KEYCLOAK_REALM === "quid" ? (
              <InfoBox
                title={t("company_details_info_title")}
                items={companyDetailsDataQuid}
              />
            ) : (
              <InfoBox
                title={t("company_details_info_title")}
                items={companyDetailsData}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <CardDetails
              isColumn={true}
              items={rightSections}
              from={"company"}
            ></CardDetails>
          </Grid>
        </Grid>
        <Grid container>
          <CardDetails
            isColumn={false}
            items={bottomSections}
            from={"company"}
          ></CardDetails>
        </Grid>
        <Grid container>
          <div className={classes.actionButtons}>
            {process.env.REACT_APP_KEYCLOAK_REALM !== "quid" && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className={btnMl}
                  disabled={loading}
                  onClick={() =>
                    history.push({
                      pathname: `/companies/${companyId}/view`,
                      state: { type: "customers" },
                    })
                  }
                >
                  {t("company__page__btn__edit")}
                </Button>
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              className={btnMl}
              disabled={loading}
              onClick={() => history.push(`/customers/${customerId}`)}
            >
              {t("company__page__btn__view__customer")}
            </Button>
          </div>
        </Grid>
      </div>
    </MainTemplate>
  );
};

export default CompanyDetail;
