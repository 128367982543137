import { Grid, makeStyles, Typography } from "@material-ui/core";
import { fetchCryptoPaymentManagementDetails } from "@portit/core/api/Crypto";
import { PaymentDetailResponse } from "@portit/core/entities/Crypto";
import Loader from "components/atoms/icons/Loader";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";
import { formatDate } from "utils";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
}));

const CryptoPaymentDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("crypto");
  const [loading, setLoading] = useState(false);
  const paymentId: number = +match.params.id;
  const [payment, setPayment] = useState({} as PaymentDetailResponse);

  useEffect(() => {
    const getCryptoPaymentDetails = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCryptoPaymentManagementDetails(paymentId);
        setPayment(res);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCryptoPaymentDetails();
  }, [paymentId]);

  const cryptoPaymentDetailsToPrint: PaymentDetailResponse = {
    paymentId: payment.paymentId,
    walletIdFrom: payment.walletIdFrom,
    beneficiaryWalletIdTo: payment.beneficiaryWalletIdTo,
    amount: payment.amount,
    network: payment.network,
    currency: payment.currency,
    smartContractId: payment.smartContractId,
    transactionId: payment.transactionId,
    status: payment.status,
    date: formatDate(payment.date, "dd/MM/yyyy"),
  };

  return (
    <>
      {loading && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <MainTemplate>
        <div className={classes.modalBody}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.title}>
                {t("crypto_payment__details__title")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="center"
            className={classes.mt20}
          >
            <Grid item xs={6}>
              <DetailsBlock
                label={t("details__crypto_payment__details")}
                toPrint={cryptoPaymentDetailsToPrint}
              />
            </Grid>
          </Grid>
        </div>
      </MainTemplate>
    </>
  );
};

export default CryptoPaymentDetails;
