import { Grid, makeStyles, Typography } from "@material-ui/core";
import { fetchCryptoSmartContractBySmartContractId } from "@portit/core/api/Crypto";
import { SmartContractBalanceDetailResponse } from "@portit/core/entities/Crypto";
import Loader from "components/atoms/icons/Loader";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import promptsSlice from "store/reducers/prompts.reducer";
import MainTemplate from "templates/MainTemplate";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
}));

const CryptoBalanceDetails: React.FC<
  RouteComponentProps<{ walletId: string; smartContractId: string }>
> = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("crypto");
  const [loading, setLoading] = useState(false);
  const walletId: number = +match.params.walletId;
  const smartContractId: number = +match.params.smartContractId;
  const [smartContract, setSmartContract] = useState(
    {} as SmartContractBalanceDetailResponse
  );

  useEffect(() => {
    const getCryptoPaymentDetails = async (): Promise<void> => {
      setLoading(true);
      try {
        const res = await fetchCryptoSmartContractBySmartContractId(
          walletId,
          smartContractId
        );
        setSmartContract(res);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    void getCryptoPaymentDetails();
  }, [walletId]);

  const cryptoSmartcontractDetailsToPrint: SmartContractBalanceDetailResponse = {
    currency: smartContract.currency,
    network: smartContract.network,
    smartContractId: smartContract.smartContractId,
    tokenIds: smartContract.tokenIds,
  };

  return (
    <>
      {loading && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <MainTemplate>
        <div className={classes.modalBody}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.title}>
                {t("crypto_payment__details__title")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justify="center"
            className={classes.mt20}
          >
            <Grid item xs={6}>
              <DetailsBlock
                label={t("details__crypto_payment__details")}
                toPrint={cryptoSmartcontractDetailsToPrint}
              />
            </Grid>
          </Grid>
        </div>
      </MainTemplate>
    </>
  );
};

export default CryptoBalanceDetails;
